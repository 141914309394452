import _ from 'lodash';
export { headers, customHeaders } from '@/lib/utils/headersOption';
export { mbToBytes } from './mbToBytes';
export { expressionString } from './expressionString';
export { resizeImage } from './resizeImage';
export {
  UTC2ddmmyyyy,
  getDatesBetweenDates,
  defaultDateRange,
  dateWithinRange,
  getDateString,
} from './dates';
export { isURL } from './isURL';
export { replaceSmartQuotes } from './stringTools';

export const toggleInArray = (array: string[], value: string) => {
  // eslint-disable-next-line
  // @ts-ignore
  const index = array.indexOf(value);
  if (index === -1) {
    // eslint-disable-next-line
    // @ts-ignore
    array.push(value);
  } else {
    array.splice(index, 1);
  }

  return array;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeDuplicates = (array: any[]): any[] => Array.from(new Set(array));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arraysAreEqual = (a1: any[], a2: any[]) => _.isEqual(_.sortBy(a1), _.sortBy(a2));

export const upperCaseFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
