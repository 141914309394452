<template>
  <v-card class="pa-1 ma-1" outlined tile :height="height">
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-toolbar-title class="title white--text pl-0"> </v-toolbar-title>
      <slot name="title"></slot>
      <v-spacer></v-spacer>
      <v-btn color="white" icon>
        <slot name="action"></slot>
      </v-btn>
    </v-app-bar>
    <slot name="body"></slot>
    <slot></slot>

    <div class="container">
      <div class="item item-description">
        <slot name="description"></slot>
      </div>
      <div class="item text-h4">
        <slot name="value"></slot>
      </div>
      <div class="item"></div>
    </div>

    <v-fade-transition>
      <v-overlay :value="overlay" absolute color="#036358">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
  </v-card>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
}

.item {
  height: 50px;
}

.item-description {
  flex-grow: 1;
  overflow: scroll;
}

.item + .item {
  margin-left: 2%;
}
</style>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'base-chart-card' as string,
  props: {
    height: { type: Number, default: 300 },
    loading: { type: Boolean, default: true },
  },
  data: () => ({
    overlay: true,
  }),
  watch: {
    loading: {
      handler(newValue) {
        !newValue &&
          setTimeout(() => {
            this.overlay = false;
          }, this.delay);
      },
      immediate: true,
    },
  },
  computed: {
    delay(): number {
      const min = 300;
      const max = 1000;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
});
</script>
