export const resizeImage = (
  imageUrl: string | null | undefined,
  width: number,
  height: number,
  cropMode: 'fill' | 'fit' = 'fill',
  placeholderIt = false
): string => {
  if (!imageUrl) {
    if (placeholderIt) {
      return `https://via.placeholder.com/${width}x${height}.png?text=No+Image`;
    }
    return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
  }

  if (imageUrl.indexOf('https://res.cloudinary.com/strata/image/upload/') === -1) {
    return imageUrl;
  }

  const imageUrlAppend = imageUrl.replace('https://res.cloudinary.com/strata/image/upload/', '');

  const attributes = [
    `w_${width * window.devicePixelRatio}`,
    `h_${height * window.devicePixelRatio}`,
    `c_${cropMode}`,
    'q_auto:good',
    'fl_any_format',
  ];

  return `https://res.cloudinary.com/strata/image/upload/${attributes.join(',')}/${imageUrlAppend}`;
};
