import { mbToBytes } from '@/lib/utils';
interface Alert {
  level: 'info' | 'error' | 'warning';
  message: string;
}
interface App {
  navDrawer: boolean;
  appName: string;
  companyName: string;
  colours: { [key: string]: string };
  alerts: [Alert] | null;
  datatableItemsPerPage: number;
  defaultImage: string;
  defaultIcon: string;
  maxImageUploadSizeinMB: number;
  scrollToObserver: string;
}

export interface AppState {
  app: App;
}
const state: AppState = {
  app: {
    navDrawer: false,
    appName: 'Strata 2 Admin',
    companyName: 'Cirrus Connect',
    colours: {
      Production: '#FF4500',
      Test: '#778899',
      Development: '#429AE4',
    },
    alerts: null,
    datatableItemsPerPage: 30,
    defaultImage: 'image-default.png',
    defaultIcon: 'default-icon.svg',
    maxImageUploadSizeinMB: 4,
    scrollToObserver: 'cms-base-observer',
  } as App,
};
const mutations = {
  DRAWER_: (state: AppState, payload: boolean) => (state.app.navDrawer = payload),
  ALERT: (state: AppState, alert: Alert) => state.app.alerts?.push(alert),
};

const getters = {
  drawer: (state: AppState) => state.app.navDrawer,
  alerts: (state: AppState) => state.app.alerts,
  appName: (state: AppState) => {
    const env = process.env.VUE_APP_ENV !== 'Production' ? `(${process.env.VUE_APP_ENV})` : '';
    return `${state.app.appName} ${env}`;
  },
  navBarColor: (state: AppState): string => {
    const color: string = state.app.colours[process.env.VUE_APP_ENV || 'blue'] || 'blue';
    return color;
  },
  datatableItemsPerPage: (state: AppState): number => state.app.datatableItemsPerPage || 10,
  companyName: (state: AppState): string => state.app.companyName || '',
  defaultAssetImage: (state: AppState) => state.app.defaultImage || '',
  defaultAssetIcon: (state: AppState) => state.app.defaultIcon || '',
  maxImageUploadSize: (state: AppState) => mbToBytes(state.app.maxImageUploadSizeinMB),
  scrollToObserver: (state: AppState) => state.app.scrollToObserver || '',
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
