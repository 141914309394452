<template>
  <v-row align="start">
    <slot></slot>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'BaseColumnContainer',
});
</script>

<style lang="scss" scoped>
.v-list {
  border-radius: 0;
}
</style>
