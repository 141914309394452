<template>
  <div class="main">
    <div class="left-side">
      <v-icon>{{ $icon('move') }}</v-icon>
    </div>
    <div class="section" width="60%">
      <slot>default text</slot>
    </div>
    <div class="right-side">
      <v-btn icon @click="onDelete">
        <v-icon>{{ $icon('delete') }}</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main {
  display: flex;
  margin-bottom: 20px;
}

.left-side {
  flex-basis: 40px;
  flex-shrink: 0;
  margin: 4px;
}

.section {
  flex-grow: 1;
  min-width: 600px;
}

.right-side {
  flex-basis: 40px;
  flex-shrink: 0;
  margin: 4px;
}
</style>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'cms-base-block',
  methods: {
    onDelete(): void {
      this.$emit('delete-cms-block');
    },
  },
});
</script>
