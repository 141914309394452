<template>
  <v-alert class="mt-4" dense text type="info">{{ text }}</v-alert>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'base-info-alert' as string,
  props: { text: { type: String, required: true } },
});
</script>
