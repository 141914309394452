import { MutationTree } from 'vuex';
import * as I from '@/interfaces';
import { CMSState } from './interfaces';

export enum MutationTypes {
  SET_MEDIA_LIBRARY = 'SET_MEDIA_LIBRARY',
}

export type Mutations<S = CMSState> = {
  [MutationTypes.SET_MEDIA_LIBRARY](state: S, payload: I.MediaLibraryItem[]): void;
};

export const mutations: MutationTree<CMSState> & Mutations = {
  SET_MEDIA_LIBRARY(state: CMSState, payload: I.MediaLibraryItem[] | null) {
    state.cms.mediaLibrary = payload;
  },
};
