import Vue from 'vue';
import { ActionTree, ActionContext, DispatchOptions } from 'vuex';
import * as I from '@/store/modules/programme/interfaces';
import { MutationTypes, Mutations } from './mutations';
import { Getters as ModuleGetters } from '@/store/modules/programme/journey/getters';
import { activityCreate, activityDelete, activityUpdate } from '@/store/apis';

import { ActionTypes as ModuleActionTypes, Actions as ModuleActions } from '../module/actions';

export enum ActionTypes {
  ACTIVITY_CREATE = 'activityCreate',
  ACTIVITY_EDIT = 'activityEdit',
  ACTIVITY_DELETE = 'activityDelete',
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K | string,
    payload: Parameters<Mutations[K]>[1] | null,
    options?: { root: boolean }
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<I.ProgrammeState, I.ProgrammeState>, 'commit'>;

type AugmentedActionDispatch = {
  commit<K extends keyof Mutations>(
    key: K | string,
    payload: Parameters<Mutations[K]>[1] | null,
    options?: { root: boolean }
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof ModuleActions>(
    key: K,
    payload: Parameters<ModuleActions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<ModuleActions[K]>;
} & {
  getters: {
    [K in keyof ModuleGetters]: ReturnType<ModuleGetters[K]>;
  };
} & Omit<ActionContext<I.ProgrammeState, I.ProgrammeState>, 'dispatch'>;

export interface Actions {
  [ActionTypes.ACTIVITY_CREATE](
    { commit, dispatch }: AugmentedActionDispatch,
    payload: I.Activity
  ): Promise<void>;
  [ActionTypes.ACTIVITY_EDIT](
    { commit }: AugmentedActionContext,
    payload: I.Activity
  ): Promise<void>;
  [ActionTypes.ACTIVITY_DELETE](
    { commit }: AugmentedActionDispatch,
    payload: string
  ): Promise<void>;
}

export const actions: ActionTree<I.ProgrammeState, I.ProgrammeState> & Actions = {
  async [ActionTypes.ACTIVITY_CREATE]({ commit, dispatch, getters }, payload: I.Activity) {
    try {
      commit('Loading/START_LOADING', null, { root: true });
      const { data } = await activityCreate(payload);
      commit(MutationTypes.ACTIVITY_ADD, data);

      const module = getters.moduleById;

      await dispatch(ModuleActionTypes.MODULE_EDIT, module);

      Vue.prototype.$log({
        methodName: 'Journey:create:success',
        newValue: data,
      });
    } catch (error) {
      Vue.prototype.$log({
        methodName: 'Journey:create:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },

  async [ActionTypes.ACTIVITY_EDIT]({ commit }, payload: I.Activity) {
    try {
      commit('Loading/START_LOADING', null, { root: true });

      const { data } = await activityUpdate(payload);
      Vue.prototype.$log({
        methodName: 'Activity:update:success',
        newValue: data,
      });

      commit(MutationTypes.ACTIVITY_UPDATE, data);
    } catch (error) {
      console.log(error);
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },

  async [ActionTypes.ACTIVITY_DELETE]({ commit, dispatch, getters }, payload: string) {
    try {
      commit('Loading/START_LOADING', null, { root: true });

      await activityDelete(payload);

      Vue.prototype.$log({
        methodName: 'Activity:delete',
        deletedId: payload,
      });

      commit(MutationTypes.ACTIVITY_DELETE, payload);
      commit(MutationTypes.DESELECT_ACTIVITY, null);

      const module = getters.moduleById;

      await dispatch(ModuleActionTypes.MODULE_EDIT, module);
    } catch (error) {
      console.log('Programme/activity/delete', error.message);
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
};
