<template>
  <v-card flat tile>
    <v-container>
      <div class="layout pa-2 wrap">
        <div class="flex xs6 md6">
          <div>Select {{ name }} icon/image.</div>
          <div v-html="helperText"></div>
        </div>
        <div class="flex xs6 md6 text-md-center">
          <div class="d-flex flex-column mb-6">
            <ImageHolder :imageURL="imageIs" v-if="!hideImage" />
            <v-card class="pa-2" tile>
              <v-file-input
                flat
                full-width
                solo
                accept="image/*"
                label="Select"
                v-on:change="handleFileChange"
                :hide-input="hideInput"
              ></v-file-input>
            </v-card>
          </div>
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import { mbToBytes } from '@/lib/utils';
import { imageUpload, File } from '@/store/apis';
import ImageHolder from './imageHolder.vue';

// interface Event {
//   target: {
//     files: File[];
//   };
// }

interface Data {
  newFileObject: string | null;
  file: File | null;
  url: string | null;
}

export default Vue.extend({
  name: 'base-uploader',
  components: { ImageHolder },
  props: {
    name: { type: String, default: 'module' },
    imageURL: { type: String, default: 'temp' },
    infoText: {
      type: String,
      default: 'Please note: Your selected icon should be a transparent .png file format.',
    },
    maxMB: { type: Number, default: 5000 },
    tags: {
      type: Array as () => string[],
      default: (): string[] => ['admin-file-uploader'],
    },
    type: { type: String, default: 'image' },
    hideInput: { type: Boolean, default: false },
    hideImage: { type: Boolean, default: false },
    extensions: { type: Array as () => string[], default: () => [] },
  },
  data: (): Data => ({
    newFileObject: null,
    file: null,
    url: null,
  }),
  watch: {
    imageURL: {
      handler(newValue) {
        this.url = newValue;
      },
      immediate: true,
    },
  },
  computed: {
    imageIs(): string {
      const defaultImage = this.$store.getters['App/defaultAssetImage'];
      const image = require.context('@/assets', false, /\.png$/);
      return this.url === 'temp' ? image('./' + defaultImage) : this.url;
    },
    helperText(): string {
      return `<p>This icon will appear next to the ${this.name} title in the App.</p><p>${this.infoText}</p>`;
    },
    maxFileSize(): number {
      return this.$store.getters['App/maxImageUploadSize'];
    },
    getTags(): string {
      return this.tags.toString();
    },
    getmaxMB(): number {
      return this.maxFileSize > mbToBytes(this.maxMB) ? mbToBytes(this.maxMB) : this.maxFileSize;
    },
  },
  methods: {
    validateSize(fileSize: number): void {
      if (fileSize > this.getmaxMB) {
        throw new Error(`File size "${fileSize}" exceeds ${this.getmaxMB} limit`);
      }
    },
    validateType(fileType: string): void {
      if (!fileType.startsWith(`${this.type}/`)) {
        throw new Error(`"${fileType}" is not valid`);
      }
    },
    validateExtension(fileExtension: string) {
      const extensionLowerCase = fileExtension.toLowerCase();
      if (this.extensions.length === 0) {
        return;
      }
      if (!this.extensions.some((ex) => ex.toLowerCase() === extensionLowerCase)) {
        throw new Error(
          `"${extensionLowerCase}" is not valid. Only ${this.extensions.toString()} allowed`
        );
      }
    },
    async handleFileChange(event: File) {
      this.$store.commit('Loading/START_LOADING');
      try {
        this.file = event as File;
        this.validateSize(this.file.size);
        this.validateType(this.file.type);

        // eslint-disable-next-line
        // @ts-ignore
        const { data } = await imageUpload(this.file, this.getTags);
        this.url = data.secure_url || data.url || 'temp';
        this.$toasted.success(`Uploaded: ${this.url} `);
        this.$emit('file-uploaded', this.url);
      } catch ({ message }) {
        this.$toasted.error(`Error creating: ${message} `);
      }
      this.$store.commit('Loading/FINISH_LOADING');
    },
  },
});
</script>
