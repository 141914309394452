<template>
  <v-card-actions>
    <BaseConfirm
      v-if="allowDelete"
      v-on:base-confirm-accept="$emit('delete-accept')"
      btnText="Delete"
      acceptText="Yes delete"
      rejectText="no cancel"
    >
      <template v-slot:title>Delete?</template>
      <template v-slot:body>Are you sure, as it is not possible to undo?</template>
    </BaseConfirm>
    <v-spacer></v-spacer>
    <v-btn v-if="create" outlined v-on:click="$emit('post')" :disabled="!validForm">{{
      createLabel
    }}</v-btn>
    <v-btn v-if="edit" class="update-role" outlined v-on:click="$emit('update')"  :disabled="!validForm || !isModified">Update</v-btn>
  </v-card-actions>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'base-dialog-actions' as string,
  props: {
    edit: { type: Boolean, default: false },
    delete: { type: Boolean, default: true },
    isModified: { type: Boolean, default: true },
    create: { type: Boolean, default: false },
    validForm: { type: Boolean, default: false },
    deleteRole: { type: String, default: 'a-role' },
    updateRole: { type: String, default: 'a-role' },
    createRole: { type: String, default: 'a-role' },
    createLabel: { type: String, default: 'add' },
  },
  computed: {
    allowDelete(): boolean {
      return this.$cani(this.deleteRole) && this.edit && this.delete;
    },
    allowUpdate(): boolean {
      return this.$cani(this.updateRole) && this.edit;
    },
    allowCreate(): boolean {
      return this.$cani(this.createRole) && this.create;
    },
  },
});
</script>
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.update-role:disabled {
  background-color: $BackgroundDisabledColor;
}
.update-role:enabled {
  background-color: $BackgroundEnabledColor;
}
</style>
