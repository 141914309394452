import { Commit } from 'vuex';
import Vue from 'vue';
import {
  User,
  UserSearchFields,
  fetchOrganisationUsers,
  createUser,
  deleteUserById,
  updateUser,
  searchUsers,
  fetchOrganisationsFacilitators,
} from '@/store/apis';

export interface UsersState {
  users: {
    list: User[] | [];
    selectedId: string | null;
    fullname?: '';
  };
}
const state: UsersState = {
  users: {
    list: [] as User[],
    selectedId: null,
  },
};

const actions = {
  async fetchOrganisationUsers({ commit }: { commit: Commit }, id: string): Promise<void> {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      if (!id) {
        throw new Error('ID is invalid');
      } else {
        const response = await fetchOrganisationUsers(id);
        commit('ADD_COLLECTION', response);
      }
    } catch (error) {
      Vue.prototype.$toasted.error(`Users fetch: ${error.message}`);
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async fetchOrganisationsFacilitators({ commit }: { commit: Commit }, orgIds: string[]): Promise<void> {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      if (!orgIds) {
        throw new Error('Invalid parameters');
      } else {
        const response = await fetchOrganisationsFacilitators(orgIds);
        commit('ADD_COLLECTION', response);
      }
    } catch (error) {
      Vue.prototype.$toasted.error(`Facilitators fetch: ${error.message}`);
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async createUser({ commit }: { commit: Commit }, user: User): Promise<void> {
    localStorage.removeItem('NEW_USER_ID');
    commit('Loading/START_LOADING', null, { root: true });
    try {
      const response = await createUser(user);
      localStorage.setItem('NEW_USER_ID', response.data?.id);
      commit('ADD_USER', response.data);
      Vue.prototype.$log({ methodName: 'User:create:success', newValue: { ...user } });
    } catch (error) {
      let msg = '';
      if (error.response.status === 409) {
        msg = `User could not be added. The email address supplied already exists within the system.`;
      } else {
        msg = `Users create: ${error.message}`;
      }
      Vue.prototype.$log({
        methodName: 'User:create:error',
        newValue: error.message,
      });
      Vue.prototype.$toasted.error(msg);
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async deleteById({ commit }: { commit: Commit }, id: string): Promise<void> {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      await deleteUserById(id);
      Vue.prototype.$log({ methodName: 'User:delete', deletedId: id });
      commit('REMOVE_USER', id);
      Vue.prototype.$toasted.success('User deleted');
      Vue.prototype.$log({
        methodName: 'User:delete:success',
        deletedId: id,
      });
    } catch (error) {
      Vue.prototype.$toasted.error(`User delete: ${error.message}`);
      Vue.prototype.$log({
        methodName: 'User:delete:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async updateUser({ commit }: { commit: Commit }, body: User): Promise<void> {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      const { data } = await updateUser(body);
      Vue.prototype.$log({ methodName: 'User:update', newValue: body });
      commit('UPDATE_USER', data);
      Vue.prototype.$log({
        methodName: 'User:update:success',
        newValue: body,
      });
    } catch (error) {
      let msg = '';
      if (error.response.status === 400) {
        msg = `User is invalid: ${error.message} `;
      } else {
        msg = `Error updating: ${error.message} `;
      }

      Vue.prototype.$toasted.error(msg);
      Vue.prototype.$log({
        methodName: 'User:update:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async search({ commit }: { commit: Commit }, searchFields: UserSearchFields): Promise<void> {
    try {
      commit('ADD_COLLECTION', []);
      if (
        (searchFields.text != null && searchFields.text.length > 2) ||
        searchFields.orgId != null
      ) {
        const data = await searchUsers({
          ...searchFields,
          text: searchFields.text?.toLowerCase().trim() || null,
        });
        commit('ADD_COLLECTION', data);
      }
    } catch (error) {
      // eslint-disable-next-line
      // @ts-ignore
      console.log(`User search: ${error.message} `);
    }
  },
};

const mutations = {
  ADD_COLLECTION(state: UsersState, collection: User[]): void {
    state.users.list = collection;
  },
  RESET(state: UsersState): void {
    state.users.list = [];
    state.users.selectedId = null;
  },
  ADD_USER(state: UsersState, user: User): void {
    // eslint-disable-next-line
    // @ts-ignore
    state.users.list.unshift(user);
    state.users.selectedId = user.id;
  },
  REMOVE_USER(state: UsersState, deletedId: string): void {
    state.users.list = state.users.list.filter(({ id }: User) => id !== deletedId);
    state.users.selectedId = null;
  },
  UPDATE_USER(state: UsersState, user: User): void {
    const index = state.users.list.findIndex((item) => item.id === user.id);
    if (index !== -1) {
      state.users.list.splice(index, 1, user);
      state.users.selectedId = user.id;
    }
  },
};

const getters = {
  users: (state: UsersState): User[] => {
    if (state.users.list.length > 0) {
      return (state.users.list as User[])
        .map((user: User) => ({
          ...user,
          displayName: `${user.firstName} ${user.lastName}`,
        }))
        .sort((a: User, b: User): number => {
          const nameA = a.displayName.toLowerCase();
          const nameB = b.displayName.toLowerCase();
          let comparison = 0;
          if (nameA > nameB) {
            comparison = 1;
          } else if (nameA < nameB) {
            comparison = -1;
          }

          return comparison;
        });
    } else {
      return [];
    }
  },
  userById: (state: UsersState) => (id: string): User | null =>
    state.users.list.filter((user: User) => user.id === id)[0] || null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
