import axios from 'axios';
import { headers } from '@/lib/utils';

const BASE_URL = process.env.VUE_APP_USER_PROFILE_API;

export interface AuthUserLite {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export const fetchAuthUserProfile = async () => {
  const url = `${BASE_URL}/identity/full`;
  return await axios.get(url, await headers());
};
