<template>
  <v-btn icon dark>
    <v-icon
      :color="color"
      v-text="$icon(this.icon)"
      v-on:click.stop="onclickMethod"
      :disabled="disabled"
    ></v-icon>
  </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'base-icon-btn' as string,
  props: {
    color: { type: String, default: 'red' },
    icon: { type: String, default: 'edit' },
    listen: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onclickMethod(event: any): void {
      if (this.listen !== '') {
        this.$emit(this.listen, event);
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
