var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "pa-2", attrs: { tile: "" } },
    [
      _c("v-img", {
        staticClass: "grey darken-4",
        attrs: { src: _vm.cPicture, "max-height": "125", contain: "" },
        on: { error: _vm.onImgError },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }