import * as I from './interfaces';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

const state: I.CohortState = {
  cohorts: {
    list: [],
    selectedId: null,
    showEditor: false,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
