import { ActionTree, ActionContext } from 'vuex';
import { Mutations } from './mutations';
import * as I from './interfaces';
import { getMediaCollection } from '@/store/apis';
import { MutationTypes } from './mutations';

export enum ActionTypes {
  FETCH_MEDIA_LIBRARY = 'FETCH_MEDIA_LIBRARY',
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K | string,
    payload: Parameters<Mutations[K]>[1] | null,
    options?: { root: boolean }
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<I.CMSState, I.CMSState>, 'commit'>;

export interface Actions {
  [ActionTypes.FETCH_MEDIA_LIBRARY]({ commit }: AugmentedActionContext): Promise<void>;
}

export const actions: ActionTree<I.CMSState, I.CMSState> & Actions = {
  async [ActionTypes.FETCH_MEDIA_LIBRARY]({ commit }) {
    try {
      commit('Loading/START_LOADING', null, { root: true });
      const { data } = await getMediaCollection();
      commit(MutationTypes.SET_MEDIA_LIBRARY, data);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
};
