<template>
  <v-snackbar v-model="snackbar" :timeout="timeout" top :color="options.color">
    {{ options.text }}
    <v-btn v-if="close" color="blue" text @click="snackbar = false">Close</v-btn>
  </v-snackbar>
</template>

<script lang="ts">
/**
 * To use:
 * $root.$emit('show-snackbar', { color: 'info', text: 'the rain in Spain '})
 * */
import Vue from 'vue';
interface Options {
  color: string;
  text: string;
}
interface Data {
  snackbar: boolean;
  timeout: number;
  options: Options;
}
export default Vue.extend({
  props: {
    color: { type: String, default: 'success' },
    close: { type: Boolean, default: false },
    message: { type: String, default: 'My timeout is set to 2000.' },
  },
  mounted() {
    this.$root.$on('show-snackbar', this.show);
  },
  data: (): Data => ({
    snackbar: false,
    timeout: 2000,
    options: {} as Options,
  }),
  methods: {
    show(event: Options): void {
      // eslint-disable-next-line
      // @ts-ignore
      Object.keys(event).forEach((key) => (this.options[key] = event[key]));
      this.snackbar = true;
    },
  },
});
</script>
