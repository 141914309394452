<template>
  <v-card class="mx-auto" max-width="400" flat tile :class="darkColumn">
    <v-card-text>
      <p class="display-1 text--primary text-center">
        <v-icon size="100">{{ $icon(icon) }}</v-icon>
      </p>
      <p class="display-1 text--primary text-center">{{ title }}</p>
      <p class="text--primary text-center" v-html="text"></p>
    </v-card-text>
    <slot name="action"></slot>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'base-search-info-card' as string,
  props: {
    title: { type: String, default: 'Search for users' },
    icon: { type: String, default: 'search' },
    dark: { type: Boolean, default: false },
    text: {
      type: String,
      default:
        'Search for users either entering a search term in the search box or show all users for a particular organisation',
    },
  },
  computed: {
    darkColumn(): Record<string, unknown> {
      return {
        'strata-background-black': this.dark,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/styles/strata.scss';
</style>
