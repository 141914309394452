<template>
  <v-card-actions>
    <BaseConfirmActions v-on:base-confirm-accept="$emit('delete-accept')" btnText="Delete" acceptText="Yes delete"
      rejectText="Close" :isCohort="isCohort">
      <template v-slot:title>Delete?</template>
      <template v-slot:body>
        <div v-if="cohortcount == 0">
          Are you sure, as it is not possible to undo?
        </div>
        <div v-else="cohortcount > 0">
          <div>Cohorts that linked with this journey are shown below, please remove these cohorts from this journey and
            delete!</div>
          <div
            style="background-color: #535252;padding: 10px;max-height: 200px;line-height: 2;margin-top: 10px;overflow-y: scroll;">
            <div v-for="(item, index) in cohorts.data">
              {{ index + 1 }}: {{ item.Title }}
            </div>
          </div>
        </div>
      </template>
    </BaseConfirmActions>
    <v-spacer></v-spacer>
    <v-btn v-if="create" outlined v-on:click="$emit('post')" :disabled="!validForm">{{
      createLabel
    }}</v-btn>
    <v-btn v-if="edit" outlined v-on:click="$emit('update')" :disabled="!validForm">Update</v-btn>
  </v-card-actions>
</template>

<script lang="ts">
import Vue from 'vue';

interface Data {
  editDialog: boolean;
  isCohort: boolean;
}

export default Vue.extend({
  name: 'base-dialog-actions-message' as string,
  data: (): Data => ({
    editDialog: false,
    isCohort: false,
  }),
  props: {
    edit: { type: Boolean, default: false },
    create: { type: Boolean, default: false },
    validForm: { type: Boolean, default: false },
    deleteRole: { type: String, default: 'a-role' },
    updateRole: { type: String, default: 'a-role' },
    createRole: { type: String, default: 'a-role' },
    createLabel: { type: String, default: 'add' },
    cohorts: {},
    cohortcount: { type: Number }
  },
  computed: {
    allowDelete(): boolean {
      return this.$cani(this.deleteRole) && this.edit;
    },
    allowUpdate(): boolean {
      return this.$cani(this.updateRole) && this.edit;
    },
    allowCreate(): boolean {
      return this.$cani(this.createRole) && this.create;
    },
  },
  watch: {
    cohortcount: {
      async handler(): Promise<any> {
        if (this.cohortcount > 0) {
          this.isCohort = true;
        } else {
          this.isCohort = false;
        }
      },
      immediate: false,
    },
  }
});
</script>
