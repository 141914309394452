import { GetterTree } from 'vuex';
import * as I from '@/store/modules/programme/interfaces';

export type Getters = {
  programme(state: I.ProgrammeState): I.Programme | null;
  byName(state: I.ProgrammeState): (name: string) => I.Programme | null;
  byId(state: I.ProgrammeState): (id: string) => I.Programme | null;
  byJourneyId(state: I.ProgrammeState): (name: string) => I.Programme | null;
  listAll(state: I.ProgrammeState): I.Programme[] | null;
  selected(state: I.ProgrammeState): I.Programme | null;
  selectedId(state: I.ProgrammeState): string | null;
};

export const getters: GetterTree<I.ProgrammeState, I.ProgrammeState> & Getters = {
  // .getters["Programme/{method}"]
  programme: (state: I.ProgrammeState): I.Programme | null =>
    (state.programmes.list.filter(
      (prog) => prog._id === state.programmes.selectedProgrammeId
    )[0] as I.Programme) || null,

  byName: (state: I.ProgrammeState) => (name: string) =>
    state.programmes.list.filter((prog: I.Programme) => prog.name === name)[0] || null,

  byId: (state: I.ProgrammeState) => (id: string) =>
    state.programmes.list.filter((prog: I.Programme) => prog._id === id)[0] || null,

  listAll: (state: I.ProgrammeState) => {
    return state.programmes.list.sort((a, b) => {
      if (a.name < b.name) return -1;

      if (a.name > b.name) return 1;

      return 0;
    });
  },

  selectedId: (state: I.ProgrammeState) => state.programmes.selectedProgrammeId,

  selected: (state: I.ProgrammeState): I.Programme | null =>
    (state.programmes.list.filter(
      (prog: I.Programme) => prog._id === state.programmes.selectedProgrammeId
    )[0] as I.Programme) || null,

  byJourneyId: (state: I.ProgrammeState) => (id: string): I.Programme | null => {
    let programme = null;

    state.programmes.list.forEach((p) => {
      p.journeys.forEach((j) => {
        if (j._id === id) {
          programme = p;
        }
      });
    });

    return programme;
  },
};
