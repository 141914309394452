import { GetterTree } from 'vuex';
import * as I from '@/store/modules/programme/interfaces';

export type Getters = {
  moduleById(state: I.ProgrammeState): I.Module | null;
  activities(state: I.ProgrammeState): I.Activity[];
};

export const getters: GetterTree<I.ProgrammeState, I.ProgrammeState> & Getters = {
  moduleById: (state: I.ProgrammeState) => {
    try {
      let journey: I.Journey = {} as I.Journey;
      state.programmes.list.forEach((p) => {
        p.journeys.forEach((j) => {
          if (j._id === state.programmes.selectedJourneyId) {
            journey = { ...j };
          }
        });
      });

      if (!journey || !journey.modules) {
        return null;
      } else {
        return (
          journey.modules.filter(
            (module: I.Module) => module._id === state.programmes.selectedModuleId
          )[0] || null
        );
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  activities: (state: I.ProgrammeState) => {
    try {
      let module = {} as I.Module;

      const pIndex = state.programmes.list.findIndex(
        (p) => p._id === state.programmes.selectedProgrammeId
      );
      if (pIndex !== -1) {
        const jIndex = state.programmes.list[pIndex].journeys.findIndex(
          (j) => j._id === state.programmes.selectedJourneyId
        );
        if (jIndex !== -1) {
          const mIndex = state.programmes.list[pIndex].journeys[jIndex].modules?.findIndex(
            (m) => m._id === state.programmes.selectedModuleId
          );
          if (mIndex !== -1) {
            module = (state.programmes.list[pIndex].journeys[jIndex].modules as I.Module[])[
              mIndex as number
            ];
          }
        }
      }
      return module.activities || [];
    } catch (error) {
      throw new Error(error);
    }
  },
};
