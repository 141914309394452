import axios from 'axios';
import { headers } from '@/lib/utils';
import * as rax from 'retry-axios';

const BASE_URL = process.env.VUE_APP_REPORTING_API || '';

export const cohortUserSummary = async (cohortId: string) => {
  const url = `${BASE_URL}/cohort-v2/${cohortId}/users-meta`;

  const myAxiosInstance = axios.create();
  myAxiosInstance.defaults.raxConfig = {
    instance: myAxiosInstance,
    retry: 4,
    retryDelay: 5000,
    backoffType: 'static',
    onRetryAttempt: (err) => {
      const cfg = rax.getConfig(err);
      console.log(`Retry attempt #${cfg}`);
    },
  };

  rax.attach(myAxiosInstance);

  return await myAxiosInstance.get(url, await headers());
};
