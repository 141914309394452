import { Cohort } from '@/store/apis';
import * as I from './interfaces';
import { MutationTree } from 'vuex';

export enum MutationTypes {
  ADD_COLLECTION = 'ADD_COLLECTION',
  ADD_COHORT = 'ADD_COHORT',
  SELECTED = 'SELECTED',
  UNSELECT = 'UNSELECT',
  RESET = 'RESET',
  RESET_SELECTED = 'RESET_SELECTED',
  SHOW_COHORT_EDITOR = 'SHOW_COHORT_EDITOR',
  UPDATE_COHORT = 'UPDATE_COHORT',
  REMOVE_COHORT = 'REMOVE_COHORT',
}

export type Mutations<S = I.CohortState> = {
  [MutationTypes.ADD_COLLECTION](state: I.CohortState, payload: Cohort[]): void;
  [MutationTypes.ADD_COHORT](state: I.CohortState, payload: Cohort): void;
  [MutationTypes.SELECTED](state: I.CohortState, payload: string): void;
  [MutationTypes.UNSELECT](state: I.CohortState): void;
  [MutationTypes.RESET](state: I.CohortState): void;
  [MutationTypes.RESET_SELECTED](state: I.CohortState): void;
  [MutationTypes.SHOW_COHORT_EDITOR](state: I.CohortState, payload: boolean): void;
  [MutationTypes.UPDATE_COHORT](state: I.CohortState, payload: Cohort): void;
  [MutationTypes.REMOVE_COHORT](state: I.CohortState, payload: string): void;
};

export const mutations: MutationTree<I.CohortState> & Mutations = {
  ADD_COLLECTION(state: I.CohortState, collection: [Cohort]): void {
    state.cohorts.list = collection;
  },
  ADD_COHORT(state: I.CohortState, cohort: Cohort): void {
    (state.cohorts.list as Cohort[]).push(cohort);
  },
  SELECTED(state: I.CohortState, id: string): void {
    state.cohorts.selectedId = id;
  },
  UNSELECT(state: I.CohortState): void {
    state.cohorts.selectedId = null;
  },
  RESET(state: I.CohortState): void {
    state.cohorts.list = [];
    state.cohorts.selectedId = null;
  },
  RESET_SELECTED(state: I.CohortState): void {
    state.cohorts.selectedId = null;
  },
  SHOW_COHORT_EDITOR(state: I.CohortState, show: boolean): void {
    state.cohorts.showEditor = show;
  },
  UPDATE_COHORT(state: I.CohortState, cohort: Cohort): void {
    const index = state.cohorts.list.findIndex((c) => c.cohortId === cohort.cohortId);
    if (index !== -1) {
      state.cohorts.list.splice(index, 1, cohort);
    }
  },
  REMOVE_COHORT(state: I.CohortState, id: string): void {
    state.cohorts.list = state.cohorts.list.filter((c) => c.cohortId !== id);
    if (state.cohorts.selectedId === id) {
      state.cohorts.selectedId = 'deleted';
    }
  },
};
