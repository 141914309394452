import axios from 'axios';
import { headers, expressionString } from '@/lib/utils';

export interface User {
  id: string;
  // orgId: string;
  organizationId: string | null;
  firstName: string;
  lastName: string;
  email: string;
  displayName: string;
  roles?: [] | [string];
  lastLoggedIn?: string;
}

interface QueryBody {
  search: Array<any>;
  maxRecords: number;
  expression: string;
  orderBy?: string;
  maxResults: number;
  continuationToken: null | string;
}

export interface UserSearchFields {
  text: string | null;
  orgId: string | null;
}
export interface FacilitatorSearchFields {
  orgIds: string[] | [];
}
export interface IUserMeta {
  _id?: string;
  userId: string;
  appVersion: string;
  os: string;
  osV: string;
  releasedAssessmentReports: string[];
  systemNotificationsStatus: string;
  genericNotificationsEnabled: boolean;
  habitNotificationsEnabled: boolean;
}

const BASE_URL = process.env.VUE_APP_USER_PROFILE_API;
const APP_BASE_URL = process.env.VUE_APP_BASE_URL;

export const createUser = async (body: User) => {
  const url = `${BASE_URL}/admin/users`;
  body.firstName = body.firstName.trim();
  body.lastName = body.lastName.trim();
  body.email = body.email.trim();
  return await axios.post(url, body, await headers());
};

export const updateUser = async (body: User) => {
  const url = `${BASE_URL}/admin/users/${body.id}`;
  body.firstName = body.firstName.trim();
  body.lastName = body.lastName.trim();
  body.email = body.email.trim();
  return await axios.put(url, body, await headers());
};

export const deleteUserById = async (id: string) => {
  const url = `${BASE_URL}/admin/users/${id}`;
  return await axios.delete(url, await headers());
};

export const userPasswordReset = async (userId: string) => {
  const url = `${BASE_URL}/admin/user-profiles/password-reset`;
  const body = {
    id: userId,
  };

  return axios.post(url, body, await headers());
};

export const searchUsers = async (fields: UserSearchFields) => {
  const userCollection = [];
  const url = `${BASE_URL}/admin/users/query`;
  const searchFields = [{ key: "organisationId", value: fields.orgId }];

  const body: QueryBody = {
    search: searchFields,
    maxRecords: 100,
    expression: expressionString(fields),
    orderBy: 'FirstName ASC',
    maxResults: 100,
    continuationToken: null,
  };

  try {
    do {
      const call = await axios.post(url, body, await headers());
      const { data } = await call;
      if (data.results.length > 0) {
        userCollection.push(...data.results);
        body.continuationToken = data.continuationToken || null;
      }
    } while (body.continuationToken !== null);
    return userCollection;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchOrganisationUsers = async (orgId: string) => searchUsers({ orgId, text: null });
export const fetchOrganisationsFacilitators = async (orgIds: string[]) => searchFacilitators({ orgIds });
export const searchFacilitators = async (fields: FacilitatorSearchFields) => {
  const userCollection = [];
  const url = `${BASE_URL}/admin/facilitators`;
  const searchFields = [{ key: "organisationId", value: fields.orgIds }];

  const body: QueryBody = {
    search: searchFields,
    maxRecords: 100,
    expression: "",
    orderBy: 'FirstName ASC',
    maxResults: 100,
    continuationToken: null,
  };

  try {
    do {
      const call = await axios.post(url, body, await headers());
      const { data } = await call;
      if (data.results.length > 0) {
        userCollection.push(...data.results);
        body.continuationToken = data.continuationToken || null;
      }
    } while (body.continuationToken !== null);
    return userCollection;
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchUserByEmail = async (str: string) => {
  const userCollection = [];
  const lowerCaseStr = str.toLowerCase();
  const url = `${BASE_URL}/admin/users/query`;
  const searchFields = [{ key: "searchField", value: lowerCaseStr }];

  const body: QueryBody = {
    search: searchFields,
    maxRecords: 100,
    expression: `firstName CONTAINS '${lowerCaseStr}' OR lastName CONTAINS '${lowerCaseStr}' OR email CONTAINS '${lowerCaseStr}'`,
    orderBy: 'FirstName ASC',
    maxResults: 100,
    continuationToken: null,
  };

  try {
    do {
      const call = await axios.post(url, body, await headers());
      const { data } = await call;
      if (data.results.length > 0) {
        userCollection.push(...data.results);
        body.continuationToken = data.continuationToken || null;
      }
    } while (body.continuationToken !== null);
    return userCollection;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchUsersByOnlyEmail = async (str: string) => {
  const userCollection = [];
  const lowerCaseStr = str.toLowerCase();
  const url = `${BASE_URL}/admin/users/query`;
  const searchFields = [{ key: "searchField", value: lowerCaseStr }];

  const body: QueryBody = {
    search: searchFields,
    maxRecords: 100,
    expression: `email CONTAINS ${lowerCaseStr}`,
    orderBy: 'FirstName ASC',
    maxResults: 100,
    continuationToken: null,
  };

  try {
    do {
      const call = await axios.post(url, body, await headers());
      const { data } = await call;
      if (data.results.length > 0) {
        userCollection.push(...data.results);
        body.continuationToken = data.continuationToken || null;
      }
    } while (body.continuationToken !== null);
    return userCollection;
  } catch (error) {
    throw new Error(error);
  }
};
export const getUserMetas: (ids: string[]) => Promise<any> = async (ids: any) => {
  const url = `${APP_BASE_URL}user-meta/user-meta/byids`;
  return await axios.post(url, { userIds: ids }, await headers());
};

export const updateUserMetas: (userMetas: IUserMeta[]) => Promise<any> = async (
  userMetas: IUserMeta[]
) => {
  const url = `${APP_BASE_URL}user-meta/user-meta/update`;
  return await axios.post(url, { data: userMetas }, await headers());
};

export const getUserActivity: (ids: string[]) => Promise<any> = async (ids: any) => {
  const url = `${APP_BASE_URL}reporting/set-query/users/activity`;
  return await axios.post(url, { userIds: ids }, await headers());
};

export const getAllUserCounting: () => Promise<any> = async () => {

  const body: QueryBody = {
    search: [],
    maxRecords: 1000000000000000,
    expression: `'`,
    orderBy: 'LastName ASC',
    maxResults: 1000000000000000,
    continuationToken: null,
  };
  const url = `${BASE_URL}/admin/users/query`;
  return await axios.post(url, body, await headers());
};
