import { GetterTree } from 'vuex';
import { CMSState } from './interfaces';
import * as I from '@/interfaces';

export type Getters = {
  completeLibrary(state: CMSState): I.MediaLibraryItem[] | [];
};

export const getters: GetterTree<CMSState, CMSState> & Getters = {
  completeLibrary: (state: CMSState) => {
    if (state.cms.mediaLibrary && state.cms.mediaLibrary.length > 0) {
      return state.cms.mediaLibrary;
    } else {
      return [];
    }
  },
};
