import { Role } from './index';

export const commonAbilities: string[] = [
  'cohort-list',
  'organisation-list',
  'system:userprofile:list',
  'system:userprofile:read',
  'system:system-roles:list',
  'system:system-roles:read',
  'system:platform-roles:list',
];

const roles = (): Role[] => [
  {
    id: 'superAdmin',
    name: 'super admin',
    description: 'Can access the whole admin system. Including the ability to CRUD organisations',
    abilities: [
      'organisation-edit',
      'organisation-list',
      'organisation-delete',
      'organisation-create',
      'system:administrator',
      'system:platform-roles:create',
      'system:userprofile:assign-roles',
      'previewfeatures',
    ],
  },
  {
    id: 'programmeAdmin',
    name: 'programme admin',
    description: 'CRUD programmes',
    abilities: [
      'programme-list',
      'programme-edit',
      'programme-create',
      'programme-delete',
      'journey-create',
      'journey-list',
      'journey-read',
      'journey-edit',
      'journey-delete',
      'previewfeatures',
      ...commonAbilities,
    ],
  },
  {
    id: 'userCohortAdmin',
    name: 'user cohort admin',
    description: 'CRUD user and cohorts',
    abilities: [
      'cohort-edit',
      'cohort-list',
      'cohort-create',
      'cohort-delete',
      'programme-list',
      'system:userprofile:edit',
      'system:userprofile:list',
      'system:userprofile:read',
      'system:userprofile:create',
      'system:userprofile:delete',
      'system:platform-roles:list',
      ...commonAbilities,
    ],
  },
  {
    id: 'reportingAdmin',
    name: 'reporting admin',
    description: 'View reports',
    abilities: ['report-create', ...commonAbilities],
  },
  {
    id: 'contentAdmin',
    name: 'content admin',
    description: 'CRUD content library and tags',
    abilities: [
      'content-create',
      'content-list',
      'content-edit',
      'content-delete',
      'tag-create',
      'tag-list',
      'tag-edit',
      'tag-delete',
      'habitBank-create',
      'habitBank-list',
      'habitBank-edit',
      'habitBank-delete',
      ...commonAbilities,
    ],
  },
  {
    id: 'facilitator',
    name: 'facilitator',
    description: 'Allow a user to be able to switch programmes in the App/Web App',
    abilities: ['cirrus-facilitator', 'learning-journey-read', 'organisation-list', 'cohort-list', 'previewfeatures'],
  },
  {
    id: 'learner',
    name: 'learner',
    description:
      'No abilities except logging into the app or webapp. E.g., clients and participants',
    abilities: [],
  },
];

export default roles;
