<template>
  <h3 v-bind="$attrs">
    <v-icon v-if="icon">{{ icon }}</v-icon>
    {{ title }}
  </h3>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'base-title' as string,
  props: {
    title: { type: String, required: true },
    icon: { type: String, default: null },
  },
});
</script>
