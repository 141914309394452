import { GetterTree } from 'vuex';
import * as I from './interfaces';

export type Getters = {
  dateRange(state: I.FilterState): string[] | [];
  cohortIds(state: I.FilterState): string[] | [];
  journeyId(state: I.FilterState): string | null;
  filters(state: I.FilterState): I.Filter;
};

export const getters: GetterTree<I.FilterState, I.FilterState> & Getters = {
  dateRange: ({ filter }: I.FilterState) => {
    return filter.dateRange || [];
  },
  cohortIds: ({ filter }: I.FilterState) => {
    return filter.cohortIds || [];
  },
  journeyId: ({ filter }: I.FilterState) => {
    return filter.journeyId || null;
  },
  filters: ({ filter }: I.FilterState) => {
    return filter;
  },
};
