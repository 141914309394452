var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: { "close-on-content-click": false, "nudge-width": _vm.nudgeWith },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mr-2",
                      attrs: { color: "info", disabled: _vm.disabled },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { staticClass: "pr-2", attrs: { small: "" } }, [
                    _vm._v(_vm._s(_vm.$icon(_vm.icon))),
                  ]),
                  _vm._v(" " + _vm._s(_vm.btnText) + " "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#30618B", width: "400px" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "blue", dark: "", flat: "" } },
            [
              _c("v-icon", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.$icon(_vm.icon))),
              ]),
              _c("v-toolbar-title", { staticClass: "text-capitalize" }, [
                _vm._v(" " + _vm._s(_vm.toolbarText) + " "),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _vm._t(
            "default",
            function () {
              return [_vm._v("popup menu card here")]
            },
            { close: _vm.close }
          ),
          _c(
            "v-card-actions",
            [
              _vm._t(
                "actions",
                function () {
                  return [_vm._v(" action "), _c("v-spacer"), _vm._v("action ")]
                },
                { close: _vm.close }
              ),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }