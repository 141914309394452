import Axios from 'axios';
import { headers } from '@/lib/utils';
import { CMS } from '@/interfaces';
import { ProjectBlock } from './assessment';

const BASE_URL = process.env.VUE_APP_LEARNING_JOURNEY_API || '';
export interface Activity {
  _id?: string;
  title: string;
  type?: string;
  subtitle?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  imageUrl?: string;
  content?: CMS;
  meta?: any;
}

export interface BaseModule {
  _id?: string;
  title: string;
  imageUrl?: string;
  subtitle?: string;
  active?: boolean;
  isNextRow?: boolean;
  iconName?: string;
  iconColor?: string;
  rowTitle?: string;
  startDate?: string;
  endDate?: string;
}

export interface DBModule extends BaseModule {
  activities?: string[];
}

export interface Module extends BaseModule {
  activities?: Activity[];
}
export interface Journey {
  _id?: string;
  title: string;
  modules?: Module[];
  programmeName?: string;
  projects?: ProjectBlock[];
  layoutType: string;
}

export interface Programme {
  _id?: string;
  name: string;
  organisation: string;
  journeys: Journey[];
}
export interface ProgrammeRaw {
  _id?: string;
  name: string;
  organisation: string;
  journeys: string[];
}

export const createProgramme = async (newProg: ProgrammeRaw) => {
  const url = `${BASE_URL}/programme/create`;
  return await Axios.post(url, newProg, await headers());
};

export const programmeById = async (id: string) => {
  const url = `${BASE_URL}/programme/${id}`;
  return await Axios.get(url, await headers());
};

export const programmeByIdFullReferences = async (id: string) => {
  const url = `${BASE_URL}/programme/${id}/full-references`;
  return await Axios.get(url, await headers());
};

export const programmeByOrgIdWithFullReferences = async (orgId: string) => {
  const url = `${BASE_URL}/programme/org-id/${orgId}/full-references`;
  return await Axios.get(url, await headers());
};

export const editProgramme = async (id: string, editProg: ProgrammeRaw) => {
  const url = `${BASE_URL}/programme/${id}`;
  return await Axios.patch(url, editProg, await headers());
};

export const deleteProgramme = async (id: string) => {
  const url = `${BASE_URL}/programme/${id}`;
  return await Axios.delete(url, await headers());
};

export const journeyCreate = async (body: Journey) => {
  const url = `${BASE_URL}/journey/create`;
  return await Axios.post(url, body, await headers());
};

export const journeyEdit = async (id: string, body: Journey) => {
  const url = `${BASE_URL}/journey/${id}/full`;
  return await Axios.patch(url, body, await headers());
};

export const journeyDelete = async (id: string) => {
  const url = `${BASE_URL}/journey/${id}`;
  return await Axios.delete(url, await headers());
};

export const journeyMeta = async (id: string) => {
  const url = `${BASE_URL}/meta/cohort/${id}`;
  return await Axios.get(url, await headers()).catch((err) => {
    if (err.response.status === 404) return {} as any;
    console.log('Failed to fetch cohort meta:' + err.message);
    return {} as any;
  });
};

export const postJourneyMeta = async (id: string, body: any) => {
  const url = `${BASE_URL}/meta/cohort/${id}`;
  return await Axios.post(url, body, await headers());
};

export const moduleCreate = async (module: Module) => {
  const url = `${BASE_URL}/module/create`;
  return await Axios.post(url, module, await headers());
};

export const moduleUpdate = async (id: string, module: DBModule) => {
  const url = `${BASE_URL}/module/${id}`;
  return await Axios.patch(url, module, await headers());
};

export const moduleDelete = async (id: string) => {
  const url = `${BASE_URL}/module/${id}`;
  return await Axios.delete(url, await headers());
};

export const activityList = async () => {
  const url = `${BASE_URL}/activity/list`;
  return await Axios.get(url, await headers());
};

export const activityCreate = async (activity: Activity) => {
  const url = `${BASE_URL}/activity/create`;
  return await Axios.post(url, activity, await headers());
};

export const activityDelete = async (id: string) => {
  const url = `${BASE_URL}/activity/${id}`;
  return await Axios.delete(url, await headers());
};

export const activityUpdate = async (activity: Activity) => {
  const url = `${BASE_URL}/activity/${activity._id}`;
  return await Axios.patch(url, activity, await headers());
};

export const cloneModule = async (moduleId: string, body: { title?: string }) => {
  const url = `${BASE_URL}/clone/module/${moduleId}`;
  return await Axios.post(url, body, await headers());
};

export const cloneActivity = async (activityId: string, body: { title?: string }) => {
  const url = `${BASE_URL}/clone/activity/${activityId}`;
  return await Axios.post(url, body, await headers());
};

export const userProgress = async (users: string[], journeyId: string) => {
  const url = `${BASE_URL}/report/progress/user-group`;

  return await Axios.post(
    url,
    {
      journeyId,
      users,
    },
    await headers()
  );
};
