<template>
  <div class="text-center">
    <v-dialog v-model="isLoading" hide-overlay persistent width="300">
      <v-card color="primary">
        <v-card-text>
          {{ text }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  props: {
    text: { type: String, default: 'Loading, please wait' },
    title: { type: String, default: 'Start loading' },
  },
  computed: {
    isLoading: {
      get(): boolean {
        return this.$store.state['Loading'].loading;
      },
      set(): void {
        return;
      },
    },
  },
});
</script>
