<template>
  <v-col :cols="cols">
    <v-card
      v-bind:class="{ 'flat-grey-variant': variant === 'flat-grey' }"
      tile
      min-height="100vh"
      class="pb-2"
    >
      <slot name="header"></slot>
      <slot name="body"></slot>
      <slot name="footer"></slot>
    </v-card>
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  props: {
    cols: { type: String, default: '3' },
    variant: { type: String, default: null },
  },
});
</script>
<style lang="scss" scoped>
@import '@/assets/styles/strata.scss';
</style>
<style lang="scss">
.v-card.flat-grey-variant {
  background: #2d2d2d;
  header.v-toolbar {
    background: #2d2d2d;
    box-shadow: none;
  }
  .container {
    padding: 25px;
  }
}
</style>
