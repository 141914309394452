<template>
  <v-select
    :items="items"
    :label="getLabel"
    flat
    outlined
    :item-text="text"
    :item-value="value"
    v-on:change="change"
    v-on:click="click"
  >
    <template v-slot:no-data>
      <v-alert type="warning">{{ alert }}</v-alert>
    </template>
  </v-select>
</template>

<script lang="ts">
import Vue from 'vue';

interface ListItem {
  value: string;
  text: string;
}
export default Vue.extend({
  name: 'base-select' as string,
  props: {
    items: { type: Array as () => ListItem[], required: true },
    alert: { type: String, default: 'There are no options' },
    label: { type: String, default: null },
    text: { type: String, default: 'name' },
    value: { type: String, default: 'id' },
    changeEvent: { type: String, default: 'select-change' },
    clickEvent: { type: String, default: 'select-click' },
  },
  computed: {
    getLabel(): string | null {
      return this.label || null;
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change(id: any): void {
      if (id) {
        this.$emit(this.changeEvent, id);
      }
    },
    click(): void {
      // Do nothing
    },
  },
});
</script>
