import axios from 'axios';
import { headers } from '@/lib/utils';
import { Journey } from './journey';

const BASE_URL = process.env.VUE_APP_COHORT_API;
const REPORTING_URL = process.env.VUE_APP_REPORTING_API;

export interface Cohort {
  title: string;
  cohortId: string;
  learningJourneyId?: string;
  userIds: string[];
  facilitatorIds: string[];
  organisationId: string;
  description?: string;
  startDate: string;
  endDate: string;
  experiments: Object[]
}

export interface CohortWithRefs extends Cohort {
  learningJourney: Journey;
}

export const listAll = async (searchFor = '') => {
  const cohortCollection = [];
  const url = `${BASE_URL}/cohorts/query`;

  const body = {
    expression: `organisationId EQ '${searchFor}'`,
    maxResults: 100,
    organisationId: `${searchFor}`,
    maxRecords: 100,
    continuationToken: null,
  };

  try {
    do {
      const call = await axios.post(url, body, await headers());
      const { data } = await call;
      if (data.results.length > 0) {
        cohortCollection.push(...data.results);
        body.continuationToken = data.continuationToken || null;
      }
    } while (body.continuationToken !== null);
    return cohortCollection;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const listAllUsers = async (searchFor = '') => {
  const cohortCollection = [];
  const url = `${BASE_URL}/cohorts/query/with-references`;
  let userIds = [];
  userIds.push(searchFor);

  const body = {
    expression: `userIds CONTAINS '${searchFor}'`,
    maxResults: 100,
    userIds: userIds && userIds.length>0 ? userIds: [],
    maxRecords: 100,
    continuationToken: null,
  };

  try {
    do {
      const call = await axios.post(url, body, await headers());
      const { data } = await call;
      if (data.results.length > 0) {
        cohortCollection.push(...data.results);
        body.continuationToken = data.continuationToken || null;
      }
    } while (body.continuationToken !== null);
    return cohortCollection;
  } catch (error: any) {
    throw new Error(error);
  }
};
export const listAllCohortsOfFacilitators = async (facId :String[]=[]) => {
  const cohortCollection = [];
  const url = `${BASE_URL}/cohorts/query/with-facilitator`;
  const body = {
    facilitatorIds: facId && facId.length>0? facId: [],
    maxRecords: 100,
    continuationToken: null,
  };
  try {
    do {
      const call = await axios.post(url, body, await headers());
      const { data } = await call;
      if (data.results.length > 0) {
        cohortCollection.push(...data.results);
        body.continuationToken = data.continuationToken || null;
      }
    } while (body.continuationToken !== null);
    return cohortCollection;
  } catch (error: any) {
    throw new Error(error);
  }
};
export const cohortSave = async (cohort: Cohort) => {
  const url = `${BASE_URL}/cohorts/create`;
  await cohortReport(cohort);
  return axios.post(url, cohort, await headers());
};

export const cohortUpdate = async (cohort: Cohort) => {
  const url = `${BASE_URL}/cohorts/${cohort.cohortId}`;
  await cohortReport(cohort);
  return axios.put(url, cohort, await headers());
};

export const cohortDelete = async (cohortId: string) => {
  const url = `${BASE_URL}/cohorts/${cohortId}`;
  return axios.delete(url, await headers());
};

export const cohortReport = async (cohort: Cohort) => {
  const url = `${REPORTING_URL}/report/add`;
  return axios.post(url, { ...cohort, type: 'cohort' }, await headers());
};

export const cohortLinkedWithJourney = async (journeyId: string) => {
  const url = `${BASE_URL}/cohorts/list/linked-with-journey/${journeyId}`;
  return await axios.get(url, await headers());
};

export const cohortCount = async (userIds: []) => {
  const url = `${BASE_URL}/cohorts/countforuser`;
  return axios.post(url, { userIds: userIds, maxRecords: 100, continuationToken: null }, await headers());
};

