import _Vue from 'vue';

export default {
  install(vue: typeof _Vue) {
    vue.prototype.$scrollTo = (id: string): void => {
      if (document.getElementById(id)) {
        document.getElementById(id)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          // inline: 'start',
        });
      } else {
        console.log(`IS not found ${id}`);
      }
    };
  },
};
