// @ts-nocheck
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import Loading from './modules/loading';
import AuthUser from './modules/authUser';
import Organisation from './modules/organisation';
import Cohort from './modules/cohorts/index';
import App from './modules/app';
import User from './modules/users';
import Role from './modules/roles';
import Programme from './modules/programme/index';
import Filters from './modules/filter/index';
import Report from './modules/reporting/index';
import CMS from './modules/CMS/index';
import Facilitator from './modules/facilitators';

Vue.use(Vuex);

const plugins = [];

export default new Vuex.Store({
  // state: {},
  mutations: {},
  actions: {},
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  modules: {
    App,
    AuthUser,
    Cohort,
    CMS,
    Filters,
    Loading,
    Organisation,
    Programme,
    Report,
    Role,
    User,
    Facilitator,
  },
});
