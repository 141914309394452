var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-btn",
    _vm._b({ on: { click: _vm.goBack } }, "v-btn", _vm.$attrs, false),
    [
      _c(
        "v-icon",
        _vm._b({ staticClass: "mr-1" }, "v-icon", _vm.$attrs, false),
        [_vm._v("mdi-arrow-left")]
      ),
      _vm._v("Back "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }