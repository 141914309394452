import axios from 'axios';
import { headers } from '@/lib/utils';

const BASE_URL = process.env.VUE_APP_ORGANISATION_API;

export interface Organisation {
  id: string;
  name: string;
}

export const deleteOrganisation = async (org: Organisation) => {
  if (!org.id) {
    return Promise.reject({ message: 'No Organisation ID' });
  } else {
    const url = `${BASE_URL}/organisations`;
    return axios.delete(`${url}/${org.id}`, await headers()).then((response) => response);
  }
};

export const updateOrganisation = async (org: Organisation) => {
  const url = `${BASE_URL}/organisations/${org.id}`;
  return await axios.put(url, org, await headers());
};

export const createOrganisation = async (org: Organisation) => {
  const url = `${BASE_URL}/organisations/create`;
  return await axios.post(url, org, await headers());
};

export const listAllOrganisations = async (): Promise<[Organisation]> => {
  const body = {
    continuationToken: null,
    maxRecords: 4,
  };
  const url = `${BASE_URL}/organisations/list`;
  const collection: [Record<string, unknown>] | [] = [];
  do {
    const call = await axios.post(url, body, await headers());

    // eslint-disable-next-line
    // @ts-ignore
    const { data } = await call;
    if (data.results.length > 0) {
      // eslint-disable-next-line
      // @ts-ignore
      collection.push(...data.results);
      body.continuationToken = data.continuationToken || null;
    }
  } while (body.continuationToken !== null);

  // eslint-disable-next-line
  // @ts-ignore
  return collection;
};

export const asyncFetchOrgById = async (id: string) => {
  const url = `${BASE_URL}/organisations/${id}`;
  return await axios.get(url, await headers());
};
