<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="nudgeWith">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="info" v-on="on" v-bind="attrs" class="mr-2" :disabled="disabled">
        <v-icon small class="pr-2">{{ $icon(icon) }}</v-icon>
        {{ btnText }}
      </v-btn>
    </template>

    <v-card color="#30618B" width="400px">
      <v-toolbar color="blue" dark flat>
        <v-icon class="mr-2">{{ $icon(icon) }}</v-icon>

        <v-toolbar-title class="text-capitalize">
          {{ toolbarText }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <slot :close="close">popup menu card here</slot>
      <v-card-actions>
        <slot name="actions" :close="close">
          action
          <v-spacer></v-spacer>action
        </slot>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';

interface Data {
  menu: boolean;
}
export default Vue.extend({
  name: 'popup-menu' as string,
  props: {
    icon: { type: String, default: 'report' },
    btnText: { type: String, required: true },
    toolbarTitle: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    nudgeWith: { type: Number, default: 300 },
  },
  data: (): Data => ({
    menu: false,
  }),
  computed: {
    toolbarText(): string {
      return this.toolbarTitle ? this.toolbarTitle : this.btnText;
    },
  },
  methods: {
    close(): void {
      this.menu = false;
    },
  },
});
</script>
