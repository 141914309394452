import Vue from 'vue';
import { ActionTree, ActionContext } from 'vuex';
import { MutationTypes, Mutations } from './mutations';
import * as I from './interfaces';
import { lastSessions, sessionsCount } from '@/store/apis/reporting';

export enum ActionTypes {
  FETCH_LAST_SESSIONS = 'FETCH_LAST_SESSIONS',
  FETCH_SESSIONS_HISTORY = 'FETCH_SESSIONS_HISTORY',
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K | string,
    payload: Parameters<Mutations[K]>[1] | null,
    options?: { root: boolean }
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<I.ReportingState, I.ReportingState>, 'commit'>;

export interface Actions {
  [ActionTypes.FETCH_LAST_SESSIONS](
    { commit }: AugmentedActionContext,
    payload: { dateFrom: string; dateTo: string }
  ): Promise<void>;

  [ActionTypes.FETCH_SESSIONS_HISTORY](
    { commit }: AugmentedActionContext,
    payload: { dateFrom: string; dateTo: string }
  ): Promise<void>;
}

export const actions: ActionTree<I.ReportingState, I.ReportingState> & Actions = {
  async FETCH_LAST_SESSIONS({ commit }, payload: { dateFrom: string; dateTo: string }) {
    try {
      commit('Loading/START_LOADING', null, { root: true });
      const response = await lastSessions(payload.dateFrom, payload.dateTo);
      commit(MutationTypes.SET_LAST_SESSIONS, response.data.data);
    } catch (error) {
      Vue.prototype.$toasted.error(`Unable to fetch last session data: ${error.message}`);
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },

  async FETCH_SESSIONS_HISTORY({ commit }, payload: { dateFrom: string; dateTo: string }) {
    try {
      commit('Loading/START_LOADING', null, { root: true });
      const response = await sessionsCount(payload.dateFrom, payload.dateTo);
      commit(MutationTypes.SET_SESSIONS_HISTORY, response.data.data);
    } catch (error) {
      Vue.prototype.$toasted.error(`Unable to fetch session history data: ${error.message}`);
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
};
