var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card-actions",
    [
      _vm.allowDelete
        ? _c("BaseConfirm", {
            attrs: {
              btnText: "Delete",
              acceptText: "Yes delete",
              rejectText: "no cancel",
            },
            on: {
              "base-confirm-accept": function ($event) {
                return _vm.$emit("delete-accept")
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Delete?")]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _vm._v("Are you sure, as it is not possible to undo?"),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              183355870
            ),
          })
        : _vm._e(),
      _c("v-spacer"),
      _vm.create
        ? _c(
            "v-btn",
            {
              attrs: { outlined: "", disabled: !_vm.validForm },
              on: {
                click: function ($event) {
                  return _vm.$emit("post")
                },
              },
            },
            [_vm._v(_vm._s(_vm.createLabel))]
          )
        : _vm._e(),
      _vm.edit
        ? _c(
            "v-btn",
            {
              staticClass: "update-role",
              attrs: {
                outlined: "",
                disabled: !_vm.validForm || !_vm.isModified,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("update")
                },
              },
            },
            [_vm._v("Update")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }