var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-col",
    { attrs: { cols: _vm.cols } },
    [
      _c(
        "v-card",
        {
          staticClass: "pb-2",
          class: { "flat-grey-variant": _vm.variant === "flat-grey" },
          attrs: { tile: "", "min-height": "100vh" },
        },
        [_vm._t("header"), _vm._t("body"), _vm._t("footer")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }