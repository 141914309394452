import * as I from '@/store/modules/programme/interfaces';
import { MutationTypes, Mutations } from './mutations';
import {
  ProgrammeRaw,
  programmeByOrgIdWithFullReferences,
  createProgramme,
  editProgramme,
  deleteProgramme,
} from '@/store/apis';
import Vue from 'vue';
import { ActionTree, ActionContext } from 'vuex';

export enum ActionTypes {
  FETCH_PROGRAMME_BY_ORG_ID = 'fetchProgrammeByOrgId',
  PROGRAMME_CREATE = 'programmeCreate',
  PROGRAMME_EDIT = 'programmeEdit',
  PROGRAMME_DELETE = 'programmeDeleteItem',
  PROGRAMME_SELECT = 'selectProgramme',
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K | string,
    payload: Parameters<Mutations[K]>[1],
    options?: { root: boolean }
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<I.ProgrammeState, I.ProgrammeState>, 'commit'>;

export interface Actions {
  [ActionTypes.FETCH_PROGRAMME_BY_ORG_ID](
    { commit }: AugmentedActionContext,
    payload: string
  ): Promise<void>;
  [ActionTypes.PROGRAMME_CREATE](
    { commit }: AugmentedActionContext,
    payload: I.Programme
  ): Promise<void>;
  [ActionTypes.PROGRAMME_EDIT](
    { commit }: AugmentedActionContext,
    payload: { id: string; body: I.Programme }
  ): Promise<void>;
  [ActionTypes.PROGRAMME_DELETE](
    { commit }: AugmentedActionContext,
    payload: string
  ): Promise<void>;
  [ActionTypes.PROGRAMME_SELECT](
    { commit }: AugmentedActionContext,
    payload: string
  ): Promise<void>;
}

export const actions: ActionTree<I.ProgrammeState, I.ProgrammeState> & Actions = {
  async fetchProgrammeByOrgId({ commit }, orgId: string) {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      if (orgId !== null) {
        const { data } = await programmeByOrgIdWithFullReferences(orgId);
        commit(MutationTypes.ADD_PROGRAMME_COLLECTION, data);
      }

      if (orgId === null) {
        throw new Error('Organisation ID should NOT be null');
      }
    } catch (error) {
      Vue.prototype.$toasted.error(`Programmes fetch: ${error.message}`);
      Vue.prototype.$log({
        methodName: 'Programmes:fetch:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },

  async programmeCreate({ commit }, body: I.Programme) {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      const bodyRaw = {
        ...body,
        journeys: body.journeys ? body.journeys.map((j) => j._id) : [],
      } as ProgrammeRaw;
      const { data } = await createProgramme(bodyRaw);
      commit(MutationTypes.ADD_PROGRAMME, data);
      Vue.prototype.$log({
        methodName: 'Programme:create',
        newValue: data,
      });
    } catch (error) {
      Vue.prototype.$toasted.error(`Programmes create: ${error.message}`);
      Vue.prototype.$log({
        methodName: 'Programmes:fetch:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },

  async programmeEdit({ commit }, { id, body }) {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      const bodyRaw = {
        ...body,
        journeys: body.journeys.map((j) => j._id),
      } as ProgrammeRaw;
      const { data } = await editProgramme(id, bodyRaw);
      commit(MutationTypes.UPDATE_PROGRAMME, data);
      Vue.prototype.$log({ methodName: 'Programme:edit', newValue: data });
    } catch (error) {
      Vue.prototype.$toasted.error(`Programmes edit: ${error.message}`);
      Vue.prototype.$log({
        methodName: 'Programmes:fetch:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },

  async programmeDeleteItem({ commit }, id: string) {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      await deleteProgramme(id);
      commit(MutationTypes.REMOVE_PROGRAMME, id);
      Vue.prototype.$log({
        methodName: 'Programme:delete',
        deletedId: id,
      });
    } catch (error) {
      Vue.prototype.$toasted.error(`Programmes edit: ${error.message}`);
      Vue.prototype.$log({
        methodName: 'Programmes:delete:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async selectProgramme({ commit }, id: string) {
    commit(MutationTypes.SELECTED_PROGRAMME, id);
    commit('Cohort/UNSELECT', null, { root: true });
  },
};
