<template>
  <v-dialog v-model="show" persistent max-width="600px" overlay-color="grey" overlay-opacity="0.7">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title v-text="dialogTitle"></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark text @click="closeDialog">
            <v-icon>{{ $icon('close') }}</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <slot name="body" v-bind:id="id" v-bind:closeDialog="closeDialog" v-bind:dialogStatus="show"
        >some generic stuff here</slot
      >
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'base-dialog-no-activator' as string,
  props: {
    title: { type: String, default: 'Info' },
    show: {
      type: Boolean,
      default: false,
    }, // The dialog open/activator button clickable or not
    id: {
      type: String,
      default: '',
    }, // Add this to specifiy an instance of the base-dialog component
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    close: {
      handler(): void {
        this.closeDialog();
      },
      immediate: true,
    },
  },
  computed: {
    dialogTitle(): string {
      return this.title;
    },
    close(): number {
      return this.$store.getters['App/closeAllDialogs'];
    },
  },
  methods: {
    closeDialog(): void {
      // Note: This method will be called as many times as there instances of this component!
      this.dialog = false;
      this.$emit('dialog-closed');
      this.$emit('close');
    },
  },
});
</script>
