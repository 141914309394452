import { headers } from '@/lib/utils';
import store from '@/store/index';
import { AuthUserLite } from '@/store/apis';

interface PayloadBasic {
  methodName: string;
}
interface PayloadDelete extends PayloadBasic {
  deletedId: string | number;
  newValue?: never;
}
interface Payload extends PayloadBasic {
  newValue: Record<string, unknown> | string | number;
  deletedId?: never;
}

type Entity = PayloadDelete | Payload;

interface DataLogger {
  flowId?: string;
  requestId?: string;
  eventNamespace?: string; // E.g.Strata2.App.UserFlow"
  eventName?: string; // E.g. "UserAuthenticated"
  eventDate: string; // datetime (utc) - from client, if it's not there take current,
  sourceType: string; // E.g. mobile, web, service
  sourceName: string; // E.g. "admin-portal", {service}-api, strata2-mobile-app
  authUser: AuthUserLite;
  payload: PayloadDelete | Payload;
}

const BASE_URL = process.env.VUE_APP_LOG_API || '';

const authUser = (): AuthUserLite => {
  const user: AuthUserLite = store.getters['AuthUser/userLite'];
  return user;
};

const Logger = async (content: Entity) => {
  const body: DataLogger = {
    requestId: '',
    eventNamespace: content.methodName || '', // "Strata2.App.UserFlow",
    eventName: content.methodName || '', // "UserAuthenticated",
    eventDate: new Date().toISOString(),
    sourceType: 'web',
    sourceName: 'admin-portal',
    authUser: authUser(),
    payload: { ...content },
  };

  const pl = {
    ...(await headers()),
    method: 'POST',
    body: JSON.stringify(body),
  };
  try {
    fetch(`${BASE_URL}/collector/single`, pl);
  } catch (error) {
    /* eslint-disable */
    console.log("Data Collector: ", error);
    /* eslint-enable */
  }
};

export default Logger;
