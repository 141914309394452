import { actions as PA } from './programme/actions';
import { actions as JA } from './journey/actions';
import { actions as MA } from './module/actions';
import { actions as AA } from './activity/actions';

import { getters as PG } from './programme/getters';
import { getters as JG } from './journey/getters';
import { getters as MG } from './module/getters';
import { getters as AG } from './activity/getters';

import { mutations as PM } from './programme/mutations';
import { mutations as JM } from './journey/mutations';
import { mutations as MM } from './module/mutations';
import { mutations as AM } from './activity/mutations';

import * as I from '@/store/modules/programme/interfaces';

const state: I.ProgrammeState = {
  programmes: {
    list: [] as I.Programme[],
    selectedProgrammeId: null,
    selectedJourneyId: null,
    selectedModuleId: null,
    selectedActivityId: null,
  },
};
const actions = {
  ...PA,
  ...JA,
  ...MA,
  ...AA,
};
const mutations = {
  ...PM,
  ...JM,
  ...MM,
  ...AM,
  RESET_JOURNEY_MODULE_ACTIVITY(state: I.ProgrammeState) {
    state.programmes.selectedJourneyId = null;
    state.programmes.selectedModuleId = null;
    state.programmes.selectedActivityId = null;
  },
  RESET_ALL(state: I.ProgrammeState) {
    state.programmes.list = [];
    state.programmes.selectedProgrammeId = null;
    state.programmes.selectedJourneyId = null;
    state.programmes.selectedModuleId = null;
    state.programmes.selectedActivityId = null;
  },
};
const getters = {
  ...PG,
  ...AG,
  ...JG,
  ...MG,
  selectedIds: (state: I.ProgrammeState) => ({
    programmeId: state.programmes.selectedProgrammeId,
    journeyId: state.programmes.selectedJourneyId,
    moduleId: state.programmes.selectedModuleId,
    activityId: state.programmes.selectedActivityId,
  }),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
