var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card-actions",
    [
      _c("BaseConfirmActions", {
        attrs: {
          btnText: "Delete",
          acceptText: "Yes delete",
          rejectText: "Close",
          isCohort: _vm.isCohort,
        },
        on: {
          "base-confirm-accept": function ($event) {
            return _vm.$emit("delete-accept")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v("Delete?")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm.cohortcount == 0
                  ? _c("div", [
                      _vm._v(" Are you sure, as it is not possible to undo? "),
                    ])
                  : _c("div", [
                      _c("div", [
                        _vm._v(
                          "Cohorts that linked with this journey are shown below, please remove these cohorts from this journey and delete!"
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "background-color": "#535252",
                            padding: "10px",
                            "max-height": "200px",
                            "line-height": "2",
                            "margin-top": "10px",
                            "overflow-y": "scroll",
                          },
                        },
                        _vm._l(_vm.cohorts.data, function (item, index) {
                          return _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(index + 1) +
                                ": " +
                                _vm._s(item.Title) +
                                " "
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-spacer"),
      _vm.create
        ? _c(
            "v-btn",
            {
              attrs: { outlined: "", disabled: !_vm.validForm },
              on: {
                click: function ($event) {
                  return _vm.$emit("post")
                },
              },
            },
            [_vm._v(_vm._s(_vm.createLabel))]
          )
        : _vm._e(),
      _vm.edit
        ? _c(
            "v-btn",
            {
              attrs: { outlined: "", disabled: !_vm.validForm },
              on: {
                click: function ($event) {
                  return _vm.$emit("update")
                },
              },
            },
            [_vm._v("Update")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }