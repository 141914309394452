import Vue from 'vue';
import { ActionTree, ActionContext, DispatchOptions } from 'vuex';
import * as I from '@/store/modules/programme/interfaces';
import { MutationTypes, Mutations } from './mutations';
import { journeyCreate, journeyDelete, journeyEdit } from '@/store/apis';
import {
  ActionTypes as ProgrammeActionTypes,
  Actions as ProgrammeActions,
} from '../programme/actions';
import { Getters as ProgrammeGetters } from '@/store/modules/programme/programme/getters';

export enum ActionTypes {
  JOURNEY_CREATE = 'journeyCreate',
  JOURNEY_EDIT = 'journeyEdit',
  JOURNEY_DELETE = 'journeyDelete',
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K | string,
    payload: Parameters<Mutations[K]>[1] | null,
    options?: { root: boolean }
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<I.ProgrammeState, I.ProgrammeState>, 'commit'>;

type AugmentedActionDispatch = {
  commit<K extends keyof Mutations>(
    key: K | string,
    payload: Parameters<Mutations[K]>[1] | null,
    options?: { root: boolean }
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof ProgrammeActions>(
    key: K,
    payload: Parameters<ProgrammeActions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<ProgrammeActions[K]>;
} & {
  getters: {
    [K in keyof ProgrammeGetters]: ReturnType<ProgrammeGetters[K]>;
  };
} & Omit<ActionContext<I.ProgrammeState, I.ProgrammeState>, 'dispatch'>;

export interface Actions {
  [ActionTypes.JOURNEY_CREATE](
    { commit, dispatch }: AugmentedActionDispatch,
    payload: I.Journey
  ): Promise<void>;
  [ActionTypes.JOURNEY_EDIT](
    { commit }: AugmentedActionContext,
    payload: { id: string; body: I.Journey }
  ): Promise<void>;
  [ActionTypes.JOURNEY_DELETE]({ commit }: AugmentedActionContext, payload: string): Promise<void>;
}

export const actions: ActionTree<I.ProgrammeState, I.ProgrammeState> & Actions = {
  async journeyCreate({ commit, dispatch, getters }, nJourney: I.Journey) {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      const { data } = await journeyCreate(nJourney);
      commit(MutationTypes.JOURNEY_ADD, data);
      const programme = getters.programme;
      dispatch(ProgrammeActionTypes.PROGRAMME_EDIT, {
        id: programme._id,
        body: programme,
      });
      Vue.prototype.$log({
        methodName: 'Journey:create',
        newValue: data,
      });
    } catch (error) {
      Vue.prototype.$toasted.error(`Journey create: ${error.message}`);
      Vue.prototype.$log({
        methodName: 'Journey:create:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async journeyEdit({ commit }, { id, body }) {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      const { data } = await journeyEdit(id, body);
      commit(MutationTypes.JOURNEY_UPDATE, data);
      Vue.prototype.$log({
        methodName: 'Journey:edit',
        newValue: data,
      });
    } catch (error) {
      Vue.prototype.$toasted.error(`Journey edit: ${error.message}`);
      Vue.prototype.$log({
        methodName: 'Journey:edit:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async journeyDelete({ commit }, id: string) {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      await journeyDelete(id);
      commit(MutationTypes.JOURNEY_DELETE, id);
      Vue.prototype.$log({
        methodName: 'Journey:delete',
        deletedId: id,
      });
    } catch (error) {
      Vue.prototype.$toasted.error(`Journey edit: ${error.message}`);
      Vue.prototype.$log({
        methodName: 'Journey:delete:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
};
