import { MutationTree } from 'vuex';
import * as I from './interfaces';

export enum MutationTypes {
  SET_DATE_RANGE = 'SET_DATE_RANGE',
  SET_JOURNEY_ID = 'SET_JOURNEY_ID',
  TOGGLE_COHORT_ID = 'TOGGLE_COHORT_ID',
  SET_COHORT_IDS = 'SET_COHORT_IDS',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  SET_DEFAULT_DATE_RANGE = 'SET_DEFAULT_DATE_RANGE',
}

export type Mutations<S = I.FilterState> = {
  [MutationTypes.SET_DATE_RANGE](state: S, payload: string[]): void;
  [MutationTypes.SET_JOURNEY_ID](state: S, payload: string | null): void;
  [MutationTypes.TOGGLE_COHORT_ID](state: S, payload: string): void;
  [MutationTypes.SET_COHORT_IDS](state: S, payload: string[]): void;
  [MutationTypes.CLEAR_FILTERS](state: S): void;
  [MutationTypes.SET_DEFAULT_DATE_RANGE](state: S): void;
};

export const mutations: MutationTree<I.FilterState> & Mutations = {
  SET_DATE_RANGE(state: I.FilterState, payload: string[]) {
    state.filter.dateRange = payload || [];
  },
  SET_JOURNEY_ID(state: I.FilterState, payload: string) {
    state.filter.journeyId = payload || null;
  },
  SET_COHORT_IDS(state: I.FilterState, payload: string[]) {
    const uniqueSet = new Set(payload) || null;
    state.filter.cohortIds = [...uniqueSet] || null;
  },
  TOGGLE_COHORT_ID(state: I.FilterState, payload: string) {
    state.filter.cohortIds = [...new Set(state.filter.cohortIds)];
    const index = state.filter.cohortIds.indexOf(payload);
    if (index === -1) {
      state.filter.cohortIds.push(payload);
    } else {
      state.filter.cohortIds.splice(index, 1);
    }
  },
  CLEAR_FILTERS(state: I.FilterState) {
    state.filter = {
      dateRange: [],
      cohortIds: [],
      journeyId: null,
    };
  },
  SET_DEFAULT_DATE_RANGE(state: I.FilterState) {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - 30);
    today.setDate(today.getDate() + 1);
    state.filter.dateRange = [
      pastDate.toISOString().substring(0, 10),
      today.toISOString().substring(0, 10),
    ];
  },
};
