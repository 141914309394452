import * as I from './interfaces';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

const state: I.ReportingState = {
  reporting: {
    lastSessions: [],
    sessionHistory: [],
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
