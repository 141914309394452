import { Commit } from 'vuex';
import Vue from 'vue';
import {
  User,
  fetchOrganisationsFacilitators,
} from '@/store/apis';

export interface FacilitatorsState {
  facilitators: {
    list: User[] | [];
    selectedId: string | null;
    fullname?: '';
  };
}
const state: FacilitatorsState = {
  facilitators: {
    list: [] as User[],
    selectedId: null,
  },
};

const actions = {

  async fetchOrganisationsFacilitators({ commit }: { commit: Commit }, orgIds: string[]): Promise<void> {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      if (!orgIds) {
        throw new Error('Invalid parameters');
      } else {
        const response = await fetchOrganisationsFacilitators(orgIds);
        commit('ADD_COLLECTION', response);
      }
    } catch (error) {
      Vue.prototype.$toasted.error(`Facilitators fetch: ${error.message}`);
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
};

const mutations = {
  ADD_COLLECTION(state: FacilitatorsState, collection: User[]): void {
    state.facilitators.list = collection;
  },
  RESET(state: FacilitatorsState): void {
    state.facilitators.list = [];
    state.facilitators.selectedId = null;
  },

};

const getters = {
  facilitators: (state: FacilitatorsState): User[] => {
    if (state.facilitators.list.length > 0) {
      return (state.facilitators.list as User[])
        .map((user: User) => ({
          ...user,
          displayName: `${user.firstName} ${user.lastName}`,
        }))
        .sort((a: User, b: User): number => {
          const nameA = a.displayName.toLowerCase();
          const nameB = b.displayName.toLowerCase();
          let comparison = 0;
          if (nameA > nameB) {
            comparison = 1;
          } else if (nameA < nameB) {
            comparison = -1;
          }

          return comparison;
        });
    } else {
      return [];
    }
  },
  userById: (state: FacilitatorsState) => (id: string): User | null =>
    state.facilitators.list.filter((user: User) => user.id === id)[0] || null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
