import { MutationTree } from 'vuex';
import * as I from './interfaces';

export enum MutationTypes {
  SET_LAST_SESSIONS = 'SET_LAST_SESSIONS',
  SET_SESSIONS_HISTORY = 'SET_SESSIONS_HISTORY',
}

export type Mutations<S = I.ReportingState> = {
  [MutationTypes.SET_LAST_SESSIONS](state: S, payload: I.LastSessionResponse[]): void;
  [MutationTypes.SET_SESSIONS_HISTORY](state: S, payload: I.SessionHistoryResponse[]): void;
};

export const mutations: MutationTree<I.ReportingState> & Mutations = {
  SET_LAST_SESSIONS(state: I.ReportingState, payload: I.LastSessionResponse[]) {
    state.reporting.lastSessions = payload || [];
  },
  SET_SESSIONS_HISTORY(state: I.ReportingState, payload: I.SessionHistoryResponse[]) {
    state.reporting.sessionHistory = payload || [];
  },
};
