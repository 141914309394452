var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "hide-overlay": "", persistent: "", width: "300" },
          model: {
            value: _vm.isLoading,
            callback: function ($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { color: "primary" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v(" " + _vm._s(_vm.text) + " "),
                  _c("v-progress-linear", {
                    staticClass: "mb-0",
                    attrs: { indeterminate: "", color: "white" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }