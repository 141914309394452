<template>
  <v-app-bar dense dark class="narrow" tile flat>
    <BaseConfirm
      v-if="allowDelete"
      v-on:base-confirm-accept="$emit('system-bar-delete-event')"
      btnText="Delete"
      btnSize="x-small"
      acceptText="Yes delete"
      rejectText="no cancel"
    >
      <template v-slot:title>Delete?</template>
      <template v-slot:body>Are you sure, as it is not possible to undo?</template>
    </BaseConfirm>
    <v-spacer></v-spacer>
    <v-btn x-small @click="$emit('system-bar-save-event')" color="green" class="narrow-btn"
      >Save</v-btn
    >
  </v-app-bar>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/strata.scss';
.narrow {
  max-height: 40px;
  background-color: $dark;
  border-color: $dark;
  padding-bottom: 18px;
}
.narrow-btn {
  max-height: 30px;
}
</style>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'CMS-save-fab-button' as string,
  props: {
    deleteRole: {
      type: String,
      default: 'a-role',
    },
  },
  computed: {
    allowDelete(): boolean {
      return this.$cani('journey-delete');
    },
  },
});
</script>
