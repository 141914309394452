var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      { staticClass: "left-side" },
      [_c("v-icon", [_vm._v(_vm._s(_vm.$icon("move")))])],
      1
    ),
    _c(
      "div",
      { staticClass: "section", attrs: { width: "60%" } },
      [
        _vm._t("default", function () {
          return [_vm._v("default text")]
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "right-side" },
      [
        _c(
          "v-btn",
          { attrs: { icon: "" }, on: { click: _vm.onDelete } },
          [_c("v-icon", [_vm._v(_vm._s(_vm.$icon("delete")))])],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }