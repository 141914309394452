import { Route, NavigationGuardNext } from 'vue-router';
import Vue from 'vue';
import { Store } from 'vuex';

export const gateKeeperHasRole = (
  to: Route,
  from: Route,
  next: NavigationGuardNext,
  store: Store<unknown>,
  role: string
) => {
  if (
    store.getters['AuthUser/hasRole'](role) ||
    store.getters['AuthUser/hasRole']('system:administrator')
  ) {
    next();
  } else {
    if (store.getters['AuthUser/hasRole'].length < 1) {
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$auth.logout({
        returnTo: window.location.origin,
      });
      next();
    }
    next({ name: 'Denied' });
  }
};
