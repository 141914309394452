var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c(
        _vm.layout,
        { tag: "component" },
        [
          _c(
            "v-fade-transition",
            { attrs: { mode: "out-in" } },
            [
              _c("router-view", {
                key: _vm.$route.fullPath,
                attrs: { layout: _vm.layout },
                on: {
                  "update:layout": function ($event) {
                    _vm.layout = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }