import VueApollo from 'vue-apollo';
import Vue from 'vue';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from 'apollo-link-context';
import { loginRequest } from './auth/authConfig';

const AUTH_TOKEN = 'apollo-token';
const HTTP_END_POINT = process.env.VUE_APP_APOLLO_GRAPHQL_CLIENT_URI || '';

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization:
        process.env.VUE_APP_APOLLO_GRAPHQL_CLIENT_TOKEN ||
        `Bearer ${await Vue.prototype.$auth.getTokenSilently(loginRequest)}`,
    },
  };
});

const httpLink = new HttpLink({ uri: HTTP_END_POINT });
const httpLinkAuth = authLink.concat(httpLink);

const batchLink = new BatchHttpLink({ uri: HTTP_END_POINT });
const batchLinkAuth = authLink.concat(batchLink);

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  httpLinkAuth,
  batchLinkAuth
);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [],
    },
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([link]),
  cache: new InMemoryCache({ fragmentMatcher }),
  connectToDevTools: true,
  defaultOptions,
});

export const apolloProvider = new VueApollo({
  defaultClient: client,
});

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
