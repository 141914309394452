// eslint-disable-next-line
// @ts-nocheck
import { getInstance } from './authWrapper';
import { msalInstance, loginRequest } from "./authConfig";
import { AuthenticationResult, EventType, InteractionType, PopupRequest, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
//msalInstance.setNavigationClient(navigationClient);
// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {

  msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {

    if (await isAuthenticated(msalInstance, InteractionType.Redirect, loginRequest)) {
      return next();
    }
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

export async function isAuthenticated(instance: PublicClientApplication, interactionType: InteractionType, loginRequest: PopupRequest | RedirectRequest): Promise<boolean> {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not  
  return instance.handleRedirectPromise().then(() => {
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
      return true;
    }
    // User is not signed in and attempting to access protected route. Sign them in.
    if (interactionType === InteractionType.Popup) {
      return instance.loginPopup(loginRequest).then(() => {
        return true;
      }).catch(() => {
        return false;
      })
    } else if (interactionType === InteractionType.Redirect && (window.location.pathname !== '/logout/' && window.location.pathname !== '/logout')) {
      return instance.loginRedirect(loginRequest).then(() => {
        return true;
      }).catch(() => {
        return false;
      });
    }
    return false;
  }).catch(() => {
    return false;
  });
};
