var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": "600px",
        "overlay-color": "grey",
        "overlay-opacity": "0.7",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", {
                domProps: { textContent: _vm._s(_vm.dialogTitle) },
              }),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "", text: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.$icon("close")))])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._t(
            "body",
            function () {
              return [_vm._v("some generic stuff here")]
            },
            { id: _vm.id, closeDialog: _vm.closeDialog, dialogStatus: _vm.show }
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }