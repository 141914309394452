var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shouldRender
    ? _c("div", {
        class: _vm.classes,
        domProps: {
          innerHTML: _vm._s(
            require("!!html-loader!../../assets/icon/" + this.icon + ".svg")
          ),
        },
      })
    : _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {
      class: _vm.classes,
      domProps: {
        innerHTML: _vm._s(
          require("!!html-loader!../../assets/icon/" + this.icon + ".svg")
        ),
      },
    })
  },
]
render._withStripped = true

export { render, staticRenderFns }