var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "mx-auto",
      class: _vm.darkColumn,
      attrs: { "max-width": "400", flat: "", tile: "" },
    },
    [
      _c("v-card-text", [
        _c(
          "p",
          { staticClass: "display-1 text--primary text-center" },
          [
            _c("v-icon", { attrs: { size: "100" } }, [
              _vm._v(_vm._s(_vm.$icon(_vm.icon))),
            ]),
          ],
          1
        ),
        _c("p", { staticClass: "display-1 text--primary text-center" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _c("p", {
          staticClass: "text--primary text-center",
          domProps: { innerHTML: _vm._s(_vm.text) },
        }),
      ]),
      _vm._t("action"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }