import { MutationTree } from 'vuex';
import * as I from '@/store/modules/programme/interfaces';
import MutationError from '@/lib/errors/MutationError';

export enum MutationTypes {
  SELECT_ACTIVITY = 'SELECT_ACTIVITY',
  DESELECT_ACTIVITY = 'DESELECT_ACTIVITY',
  ACTIVITY_ADD = 'ACTIVITY_ADD',
  ACTIVITY_UPDATE = 'ACTIVITY_UPDATE',
  ACTIVITY_DELETE = 'ACTIVITY_DELETE',
}

export type Mutations<S = I.ProgrammeState> = {
  [MutationTypes.SELECT_ACTIVITY](state: S, payload: string): void;
  [MutationTypes.DESELECT_ACTIVITY](state: S): void;
  [MutationTypes.ACTIVITY_ADD](state: S, payload: I.Activity): void;
  [MutationTypes.ACTIVITY_UPDATE](state: S, payload: I.Activity): void;
  [MutationTypes.ACTIVITY_DELETE](state: S, payload: string): void;
};

export const mutations: MutationTree<I.ProgrammeState> & Mutations = {
  SELECT_ACTIVITY(state: I.ProgrammeState, id: string) {
    state.programmes.selectedActivityId = id || null;
  },
  DESELECT_ACTIVITY(state: I.ProgrammeState) {
    state.programmes.selectedActivityId = null;
  },
  ACTIVITY_ADD(state: I.ProgrammeState, newActivity: I.Activity) {
    state.programmes.list.forEach((programme) => {
      if (programme._id === state.programmes.selectedProgrammeId) {
        programme.journeys.forEach((journey) => {
          if (journey._id === state.programmes.selectedJourneyId) {
            journey.modules?.forEach((module) => {
              if (module._id === state.programmes.selectedModuleId) {
                if (!module.activities || module.activities === undefined) {
                  module.activities = [newActivity];
                } else {
                  module.activities.push({ ...newActivity });
                }
                state.programmes.selectedActivityId = newActivity._id as string;
              }
            });
          }
        });
      }
    });
  },
  ACTIVITY_UPDATE(state: I.ProgrammeState, editedActivity: I.Activity) {
    try {
      state.programmes.list.forEach((programme) => {
        programme.journeys.forEach((journey) => {
          journey.modules?.forEach((module) => {
            if (module.activities) {
              const index = module.activities?.findIndex(
                (activity) => activity._id === editedActivity._id
              );
              if (index !== -1) {
                module?.activities.splice(index, 1, editedActivity);
              }
            }
          });
        });
      });
    } catch ({ message }) {
      throw new MutationError(message);
    }
  },
  ACTIVITY_DELETE(state: I.ProgrammeState, deletedId: string) {
    try {
      state.programmes.list.forEach((programme) => {
        programme.journeys.forEach((journey) => {
          journey.modules?.forEach((module) => {
            if (module.activities) {
              module.activities = module.activities.filter(
                (activity) => activity._id !== deletedId
              );
            }
          });
        });
      });
    } catch ({ message }) {
      throw new MutationError(message);
    }
  },
};
