import { MutationTree } from 'vuex';
import * as I from '@/store/modules/programme/interfaces';
import MutationError from '@/lib/errors/MutationError';

export enum MutationTypes {
  SELECT_JOURNEY = 'SELECT_JOURNEY',
  JOURNEY_ADD = 'JOURNEY_ADD',
  JOURNEY_UPDATE = 'JOURNEY_UPDATE',
  JOURNEY_DELETE = 'JOURNEY_DELETE',
}

export type Mutations<S = I.ProgrammeState> = {
  [MutationTypes.SELECT_JOURNEY](state: S, payload: string): void;
  [MutationTypes.JOURNEY_ADD](state: S, payload: I.Journey): void;
  [MutationTypes.JOURNEY_UPDATE](state: S, payload: I.Journey): void;
  [MutationTypes.JOURNEY_DELETE](state: S, payload: string): void;
};

export const mutations: MutationTree<I.ProgrammeState> & Mutations = {
  SELECT_JOURNEY(state: I.ProgrammeState, id: string) {
    state.programmes.selectedJourneyId = id;
    state.programmes.selectedModuleId = null;
    state.programmes.selectedActivityId = null;
  },
  JOURNEY_ADD(state: I.ProgrammeState, journey: I.Journey) {
    state.programmes.list.forEach((programme) => {
      if (state.programmes.selectedProgrammeId === programme._id) {
        if (!programme.journeys || programme.journeys === undefined) {
          programme.journeys = [journey];
        } else {
          programme.journeys.unshift(journey);
        }

        state.programmes.selectedJourneyId = journey._id as string;
        state.programmes.selectedModuleId = null;
        state.programmes.selectedActivityId = null;
      }
    });
  },
  JOURNEY_UPDATE(state: I.ProgrammeState, updatedJourney: I.Journey) {
    try {
      state.programmes.list.forEach((programme) => {
        if (!programme.journeys || programme.journeys === undefined) {
          programme.journeys = [updatedJourney];
        } else {
          const index = programme.journeys.findIndex(
            (journey: I.Journey) => journey._id === updatedJourney._id
          );
          if (index !== -1) {
            programme.journeys.splice(index, 1, updatedJourney);
          }
        }
      });
    } catch ({ message }) {
      throw new MutationError(message);
    }
  },
  JOURNEY_DELETE(state: I.ProgrammeState, idToDelete: string) {
    state.programmes.list.forEach((programme) => {
      programme.journeys = programme.journeys.filter((prog: I.Journey) => prog._id !== idToDelete);
    });
    state.programmes.selectedJourneyId = null;
    state.programmes.selectedModuleId = null;
    state.programmes.selectedActivityId = null;
  },
};
