var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { attrs: { flat: "", tile: "" } },
    [
      _c("v-container", [
        _c("div", { staticClass: "layout pa-2 wrap" }, [
          _c("div", { staticClass: "flex xs6 md6" }, [
            _c("div", [_vm._v("Select " + _vm._s(_vm.name) + " icon/image.")]),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.helperText) } }),
          ]),
          _c("div", { staticClass: "flex xs6 md6 text-md-center" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column mb-6" },
              [
                !_vm.hideImage
                  ? _c("ImageHolder", { attrs: { imageURL: _vm.imageIs } })
                  : _vm._e(),
                _c(
                  "v-card",
                  { staticClass: "pa-2", attrs: { tile: "" } },
                  [
                    _c("v-file-input", {
                      attrs: {
                        flat: "",
                        "full-width": "",
                        solo: "",
                        accept: "image/*",
                        label: "Select",
                        "hide-input": _vm.hideInput,
                      },
                      on: { change: _vm.handleFileChange },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }