var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "pa-1 ma-1",
      attrs: { outlined: "", tile: "", height: _vm.height },
    },
    [
      _c(
        "v-app-bar",
        { attrs: { flat: "", dense: "", color: "rgba(0, 0, 0, 0)" } },
        [
          _c("v-toolbar-title", { staticClass: "title white--text pl-0" }),
          _vm._t("title"),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { color: "white", icon: "" } },
            [_vm._t("action")],
            2
          ),
        ],
        2
      ),
      _vm._t("body"),
      _vm._t("default"),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "item item-description" },
          [_vm._t("description")],
          2
        ),
        _c("div", { staticClass: "item text-h4" }, [_vm._t("value")], 2),
        _c("div", { staticClass: "item" }),
      ]),
      _c(
        "v-fade-transition",
        [
          _c(
            "v-overlay",
            { attrs: { value: _vm.overlay, absolute: "", color: "#036358" } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }