<template>
  <v-card class="pa-2" tile>
    <v-img
      :src="cPicture"
      max-height="125"
      contain
      class="grey darken-4"
      v-on:error="onImgError"
    ></v-img>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'uploader-image-holder' as string,
  props: {
    imageURL: { type: String, required: true },
  },
  data: () => ({
    failedImage: false,
  }),
  computed: {
    cPicture(): string {
      return this.failedImage ? require('@/assets/404_image.png') : this.imageURL;
    },
  },
  methods: {
    onImgError(): void {
      this.failedImage = true;
    },
  },
});
</script>
