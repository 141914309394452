import axios, { AxiosResponse } from 'axios';
import { customHeaders, headers } from '@/lib/utils';

const BASE_URL = process.env.VUE_APP_CMS_API;
const uploadPreset: string | undefined = process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;
const CLOUDINARY_URL: string | undefined = process.env.VUE_APP_CLOUDINARY_UPLOAD_URL;

export interface File {
  size: number;
  type: string;
}

export interface PreviewVideoExptended {
  videoId: string;
  videoUrl: string;
  organisation: string;
  orgId: string;
  duration: number;
  thumbnailUrl: string;
  title: string;
  uploadDate: string;
}

// upload image to a collector (root folder)
export const imageUpload = async (file: string, tags: string) => {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('tags', tags);
  const url = `${BASE_URL}/upload`;
  return axios.post(
    url,
    formData,
    await customHeaders({ 'Content-Type': 'multipart/form-data' }, url)
  );
};

// upload image to: env > orgId > images
export const thumbnailImageUpload = async (
  file: any,
  tags: any,
  orgId: string
): Promise<AxiosResponse<any>> => {
  if (uploadPreset === undefined || CLOUDINARY_URL === undefined)
    throw new Error('Could not get Cloudinary details');

  const formData = new FormData();
  formData.append('file', file);
  formData.append('tags', tags);
  formData.append('filename_override', 'true');
  formData.append(
    'folder',
    `stratav2/${getCorrectCloudinaryEnv(process.env.VUE_APP_ENV)}/organisations/${orgId}/images`
  );
  formData.append('upload_preset', uploadPreset);

  return axios.post(`${CLOUDINARY_URL}/image/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

// upload video to: env > orgId > videos
export const videoUpload = async (
  file: any,
  tags: any,
  orgId: string,
  title: string,
  callBack: Function
): Promise<AxiosResponse<any>> => {
  if (uploadPreset === undefined || CLOUDINARY_URL === undefined)
    throw new Error('Could not get Cloudinary details');

  const formData = new FormData();
  // formData.append('video', file); <= for cloudinary upload api (our service)
  formData.append('file', file); // <= for direct upload
  formData.append('tags', tags);
  formData.append('filename_override', 'true');
  formData.append('context', `caption=${title}`);
  formData.append(
    'folder',
    `stratav2/${getCorrectCloudinaryEnv(process.env.VUE_APP_ENV)}/organisations/${orgId}/videos`
  );
  formData.append('upload_preset', process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET!);

  const config = {
    onUploadProgress: (event: any): void => callBack(event),
  };

  return axios.post(`${CLOUDINARY_URL}/video/upload`, formData, config);
};

// get all videos
export const getVideos = async (orgIds: string[]) => {
  const url = `${BASE_URL}/upload/videos/all`;
  return axios.post(url, orgIds, await headers());
};

// get video by assetId
export const getVideosByAssetId = async (assetId: string) => {
  const url = `${BASE_URL}/upload/video-asset`;
  return axios.post(url, {assetId}, await headers());
};

// update existing video's tags
export const addAssetTag = async (
  assetData: PreviewVideoExptended,
  newTags: string[],
  tagName:string,
): Promise<any> => {
  const url = `${BASE_URL}/upload/update-asset/add-tag`;
  return axios.post(url, { asset: assetData, newTags,tagName }, await headers());
};

export const getMediaCollection = async () => {
  const url = `${BASE_URL}/article/list-all`;
  return await axios.get(url, await headers());
};

const getCorrectCloudinaryEnv = (env: string | undefined): string | boolean => {
  if (!env) return false;

  const lowerCaseEnv: string = env.toLowerCase();

  if (lowerCaseEnv.includes('dev')) {
    return 'dev';
  }

  if (lowerCaseEnv.includes('test')) {
    return 'test';
  }
  if (lowerCaseEnv.includes('prod')) {
    return 'production';
  }

  return 'dev';
};
