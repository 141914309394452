import {
  Organisation as OrganisationInterface,
  listAllOrganisations,
  updateOrganisation,
  createOrganisation,
  deleteOrganisation,
  Organisation,
} from '@/store/apis';
import { Commit } from 'vuex';
import Vue from 'vue';

export interface OrganisationState {
  organisations: {
    list: OrganisationInterface[] | [];
    selectedKey: number | null;
    selectedId: string | null;
  };
}
const state: OrganisationState = {
  organisations: {
    list: [],
    selectedKey: null,
    selectedId: null,
  },
};

const actions = {
  async fetchAll({ commit }: { commit: Commit }) {
    commit('Loading/START_LOADING', null, { root: true });
    // commit("RESET");
    try {
      const data: [OrganisationInterface] = await listAllOrganisations();
      commit('ADD_COLLECTION', data);
    } catch (error) {
      Vue.prototype.$toasted.error(`Organisation fetch: ${error.message}`);
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async add({ commit }: { commit: Commit }, org: OrganisationInterface) {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      const { data } = await createOrganisation(org);
      commit('ADD', data);
      Vue.prototype.$log({
        methodName: 'Organisation:created:success',
        newValue: org,
      });
      commit('SELECTED', data);
    } catch (error) {
      if (error.response.status === 400) {
        Vue.prototype.$toasted.error(`Organisation is invalid: ${error.message} `);
      } else {
        Vue.prototype.$toasted.error(`Error creating: ${error.message} `);
      }
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async update({ commit }: { commit: Commit }, org: OrganisationInterface) {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      const { data } = await updateOrganisation(org);
      commit('UPDATE', data);
      Vue.prototype.$log({
        methodName: 'Organisation:edit:success',
        newValue: org,
      });
      commit('SELECTED', data);
    } catch (error) {
      if (error.response.status === 400) {
        Vue.prototype.$toasted.error(`Organisation is invalid: ${error.message} `);
      } else {
        Vue.prototype.$toasted.error(`Error updating: ${error.message} `);
      }
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async delete({ commit }: { commit: Commit }, org: OrganisationInterface) {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      await deleteOrganisation(org);
      commit('DELETE', org.id);
      commit('UNSELECT');
      Vue.prototype.$log({
        methodName: 'Organisation:delete:success',
        deletedId: org.id,
      });
    } catch (error) {
      Vue.prototype.$toasted.error(`Error deleting: ${error.message} `);
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
};

const getters = {
  listAll: (state: OrganisationState) => {
    return state.organisations.list.sort((a, b) => {
      if (a.name < b.name) return -1;

      if (a.name > b.name) return 1;

      return 0;
    });
  },
  selected: (state: OrganisationState) => state.organisations.selectedId || null,
  orgById: (state: OrganisationState) => (id: string): Organisation =>
    // eslint-disable-next-line
    // @ts-ignore
    state.organisations.list.filter((org: Organisation) => org.id === id)[0],
  org: (state: OrganisationState) => {
    if (state.organisations.selectedId) {
      return (
        state.organisations.list.filter(
          ({ id }: OrganisationInterface) => state.organisations.selectedId === id
        )[0] || null
      );
    } else {
      return null;
    }
  },
};

const mutations = {
  ADD_COLLECTION(state: OrganisationState, collection: OrganisationInterface[]) {
    state.organisations.list = collection;
  },
  SELECTED(state: OrganisationState, { id }: OrganisationInterface) {
    state.organisations.selectedId = id;
  },
  UNSELECT(state: OrganisationState) {
    state.organisations.selectedId = null;
    state.organisations.selectedKey = null;
  },
  RESET(state: OrganisationState) {
    state.organisations.selectedId = null;
    state.organisations.selectedKey = null;
    state.organisations.list = [];
  },
  ADD(state: OrganisationState, newOrg: OrganisationInterface) {
    // eslint-disable-next-line
    // @ts-ignore
    state.organisations.list.unshift(newOrg);
    state.organisations.selectedId = newOrg.id;
  },
  UPDATE(state: OrganisationState, updtOrg: OrganisationInterface) {
    const index = state.organisations.list.findIndex((item) => item.id === updtOrg.id);
    if (index !== -1) {
      state.organisations.list.splice(index, 1, updtOrg);
      state.organisations.selectedId = updtOrg.id;
    }
  },
  DELETE(state: OrganisationState, deleteOrgId: string) {
    // eslint-disable-next-line
    // @ts-ignore
    state.organisations.list = state.organisations.list.filter(
      ({ id }: OrganisationInterface) => id !== deleteOrgId
    );
    state.organisations.selectedId = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
