import { Cohort } from '@/store/apis';
import * as I from './interfaces';
import { GetterTree } from 'vuex';

export type Getters = {
  listAll(state: I.CohortState): Cohort[] | [];
  selected(state: I.CohortState): string | null;
  selectedCohort(state: I.CohortState): Cohort | null;
  editor(state: I.CohortState): boolean;
  cohort(state: I.CohortState): (id: string) => Cohort;
  listAllCohortsOfFacilitators(state: I.CohortState): Cohort[] | [];
  listAllUsers(state: I.CohortState): Cohort[] | [];
};

export const getters: GetterTree<I.CohortState, I.CohortState> & Getters = {
  listAll: (state: I.CohortState) => {
    return state.cohorts.list.sort((a, b) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });
  },
  listAllCohortsOfFacilitators: (state: I.CohortState) => {
    return state.cohorts.list.sort((a, b) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });
  },
  listAllUsers: (state: I.CohortState) => {
    return state.cohorts.list.sort((a, b) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });
  },
  selected: (state: I.CohortState) => state.cohorts.selectedId,
  selectedCohort: (state: I.CohortState) =>
    state.cohorts.list.filter((c) => c.cohortId === state.cohorts.selectedId)[0] || null,
  editor: (state: I.CohortState) => state.cohorts.showEditor,
  cohort: (state: I.CohortState) => (id: string): Cohort =>
    state.cohorts.list.filter((cohort) => cohort.cohortId === id)[0],
};
