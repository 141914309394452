<template>
  <v-card tile flat>
    <slot></slot>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'base-v-card' as string,
  props: {
    variant: { type: String },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/styles/strata.scss';
</style>
<style lang="scss">
.v-card {
  flex: 1;
}
</style>
