var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-select", {
    attrs: {
      items: _vm.items,
      label: _vm.getLabel,
      flat: "",
      outlined: "",
      "item-text": _vm.text,
      "item-value": _vm.value,
    },
    on: { change: _vm.change, click: _vm.click },
    scopedSlots: _vm._u([
      {
        key: "no-data",
        fn: function () {
          return [
            _c("v-alert", { attrs: { type: "warning" } }, [
              _vm._v(_vm._s(_vm.alert)),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }