import axios from 'axios';
import { headers } from '@/lib/utils';

export interface Role {
  name: string;
  description: string;
  id: string;
  roleType: number;
}

const BASE_URL = process.env.VUE_APP_USER_PROFILE_API;

export const fetchAllRoles = async (): Promise<Role[]> => {
  const url = `${BASE_URL}/admin/roles/list`;
  const body = {
    continuationToken: null,
    maxRecords: 100,
  };
  const collection: [Record<string, unknown>] | [] = [];
  do {
    const call = await axios.post(url, body, await headers());

    // eslint-disable-next-line
    // @ts-ignore
    const { data } = await call;
    if (data.results.length > 0) {
      // eslint-disable-next-line
      // @ts-ignore
      collection.push(...data.results);
      body.continuationToken = data.continuationToken || null;
    }
  } while (body.continuationToken !== null);

  // eslint-disable-next-line
  // @ts-ignore
  return collection;
};
