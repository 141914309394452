// eslint-disable-next-line
// @ts-nocheck
import Vue from 'vue';
import VueRouter from 'vue-router';
import { authGuard } from '../auth';
import { gateKeeperHasRole } from '@/lib/middleware/gatekeeperRoles';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "about" */ '../views/Logout.vue'),
  },
  {
    path: '/error',
    name: 'Error',
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "about" */ '../views/Error.vue'),
  },
  {
    path: '/organisation',
    name: 'organisation',
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      gateKeeperHasRole(to, from, next, store, 'organisation-create');
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Organisation/Organisation.vue'),
  },
  {
    path: '/user',
    name: 'User',
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      gateKeeperHasRole(to, from, next, store, 'system:userprofile:create');
    },
    component: () => import(/* webpackChunkName: "user" */ '../views/Users/User.vue'),
  },
  {
    path: '/reporting',
    name: 'Reporting',
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      gateKeeperHasRole(to, from, next, store, 'report-create');
    },
    component: () => import(/* webpackChunkName: "reporting" */ '../views/Reporting/Reporting.vue'),
  },
  {
    path: '/library',
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "nestedRouteBase" */ '../views/NestedRouteBase.vue'),
    children: [
      {
        path: '',
        name: 'Content library',
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          gateKeeperHasRole(to, from, next, store, 'content-create');
        },
        component: () => import(/* webpackChunkName: "library/home" */ '../views/Library/Home.vue'),
      },
      {
        path: 'manage-tags',
        name: 'Content library tags',
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          gateKeeperHasRole(to, from, next, store, 'tag-create');
        },
        component: () =>
          import(/* webpackChunkName: "library/manage-tags" */ '../views/Library/ManageTags.vue'),
      },
      {
        path: 'media-item/:id/:action',
        name: 'Content library media item',
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          gateKeeperHasRole(to, from, next, store, 'content-create');
        },
        component: () =>
          import(/* webpackChunkName: "library/media" */ '../views/Library/Media.vue'),
      },
    ],
  },
  {
    path: '/programme',
    name: 'Programme',
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      gateKeeperHasRole(to, from, next, store, 'programme-edit');
    },
    component: () => import(/* webpackChunkName: "programme" */ '../views/Programme/Programme.vue'),
  },
  {
    path: '/cohort/:orgId?/:progId?/:cohortId?',
    name: 'Cohort',
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      gateKeeperHasRole(to, from, next, store, 'cohort-create');
    },
    component: () => import(/* webpackChunkName: "cohort" */ '../views/Cohort/Cohort.vue'),
  },
  {
    path: '/denied',
    name: 'Denied',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "denied" */ '../views/Denied.vue'),
  },
  // {
  //   path: '/journal-block-manager',
  //   name: 'Programme | Journal Block Manager',
  //   meta: { requiresAuth: true },
  //   beforeEnter(to, from, next) {
  //     gateKeeperHasRole(to, from, next, store, 'programme-edit');
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "" */ '../views/JournalBlockManager/JournalBlockManager.vue'),
  // },
  // {
  //   path: '/assessment',
  //   name: 'Strata Admin | Manage Envisia Assessments',
  //   meta: { requiresAuth: true },
  //   beforeEnter(to, from, next) {
  //     gateKeeperHasRole(to, from, next, store, 'programme-edit');
  //   },
  //   component: () => import(/* webpackChunkName: "" */ '../views/Assessment/Assessment.vue'),
  // },
  // {
  //   path: '/assessmentManager/:id',
  //   name: 'Strata Admin | Create Learning Programme',
  //   meta: { requiresAuth: true },
  //   beforeEnter(to, from, next) {
  //     gateKeeperHasRole(to, from, next, store, 'programme-edit');
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "" */ '../views/Assessment/AssessmentManager/AssessmentManager.vue'
  //     ),
  // },
  {
    path: '/experiments',
    name: 'Strata Admin | Experiments',
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      gateKeeperHasRole(to, from, next, store, 'programme-edit');
    },
    component: () => import(/* webpackChunkName: "" */ '../views/Experiment/Experiment.vue'),
  },
  {
    path: '/experiment/',
    name: 'Strata Admin | Create Experiment',
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      gateKeeperHasRole(to, from, next, store, 'programme-edit');
    },
    component: () =>
      import(
        /* webpackChunkName: "" */ '../views/Experiment/ExperimentManager/ExperimentManager.vue'
      ),
  },
  {
    path: '/experiment/:id',
    name: 'Strata Admin | Manage Experiment',
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      gateKeeperHasRole(to, from, next, store, 'programme-edit');
    },
    component: () =>
      import(
        /* webpackChunkName: "" */ '../views/Experiment/ExperimentManager/ExperimentManager.vue'
      ),
  },
  {
    path: '/videolibrary',
    name: 'Strata Admin | Video library',
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      gateKeeperHasRole(to, from, next, store, 'programme-edit');
    },
    component: () => import(/* webpackChunkName: "" */ '../views/VideoLibrary/VideoLibrary.vue'),
  },
  {
    path: '*',
    name: '_404',
    component: () => import(/* webpackChunkName: "_404" */ '../views/_404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    authGuard(to, from, next);
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
