import * as I from './interfaces';
import { getters } from './getters';
import { mutations } from './mutations';
import { defaultDateRange } from '@/lib/utils';

const state: I.FilterState = {
  filter: {
    dateRange: defaultDateRange(30),
    cohortIds: [],
    journeyId: null,
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
