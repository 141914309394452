var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { justify: "end" } },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "600px",
            "overlay-color": "grey",
            "overlay-opacity": "0.7",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: dialog }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: tooltip }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled: _vm.disabled,
                                      },
                                    },
                                    { ...tooltip, ...dialog }
                                  ),
                                  [
                                    _c("v-icon", {
                                      attrs: { color: _vm.getIconColor },
                                      domProps: {
                                        textContent: _vm._s(_vm.getIcon),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "BaseCard",
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", {
                    domProps: { textContent: _vm._s(_vm.dialogTitle) },
                  }),
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v(_vm._s(_vm.$icon("close")))])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._t(
                "body",
                function () {
                  return [_vm._v("some generic stuff here")]
                },
                {
                  id: _vm.id,
                  closeDialog: _vm.closeDialog,
                  dialogStatus: _vm.dialog,
                }
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }