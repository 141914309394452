<template>
  <v-card class="pa-1 ma-1 flex-grow-1" :min-width="minWidth" outlined tile>
    <v-card-title class="justify-center text-h4">{{ stat.value || 'n/a' }}</v-card-title>
    <v-card-text class="text-center">{{ stat.title }}</v-card-text>
    <v-fade-transition>
      <v-overlay :value="overlay" absolute color="#036358" :opacity="opacity">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
  </v-card>
</template>

<script lang="ts">
/*
To use this component wrap it with the following:
<v-card class="d-flex flex-row flex-wrap mb-6" flat tile>

<BaseStatsCard title="" value="" />
<BaseStatsCard title="" value="" />
<BaseStatsCard title="" value="" />
<BaseStatsCard title="" value="" />

</v-card>
*/

export interface StatsCard {
  value: string | number | null;
  title: string;
}
import Vue from 'vue';
export default Vue.extend({
  name: 'base-stats-card' as string,
  props: {
    stat: {
      type: Object as () => StatsCard,
      default: () => ({ title: '', value: null }),
    },
  },
  data: () => ({
    overlay: true,
    opacity: 0.5,
    minWidth: 110,
  }),
  watch: {
    stat: {
      handler(newValue: StatsCard) {
        newValue.value &&
          setTimeout(() => {
            this.overlay = false;
          }, 500);
      },
      immediate: true,
    },
  },
});
</script>
