import { UserSearchFields } from '@/store/apis';

export const expressionString = (fields: UserSearchFields): string => {
  try {
    const expression = [];
    if (fields.text) {
      expression.push(
        `firstName CONTAINS '${fields.text}' OR lastName CONTAINS '${fields.text}' OR email CONTAINS '${fields.text}'`
      );
    }

    if (fields.orgId) {
      expression.push(`organizationId EQ '${fields.orgId}'`);
    }

    return expression.join(' AND ');
  } catch (error) {
    throw new Error('Fields: text: string, orgId: string');
  }
};
