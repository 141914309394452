<template>
  <v-row justify="end">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      overlay-color="grey"
      overlay-opacity="0.7"
    >
      <template v-slot:activator="{ on: dialog }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn icon v-on="{ ...tooltip, ...dialog }" :disabled="disabled">
              <v-icon v-text="getIcon" :color="getIconColor"></v-icon>
            </v-btn>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </template>
      <BaseCard>
        <v-toolbar dark color="primary">
          <v-toolbar-title v-text="dialogTitle"></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark text @click="dialog = false">
              <v-icon>{{ $icon('close') }}</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <slot
          name="body"
          v-bind:id="id"
          v-bind:closeDialog="closeDialog"
          v-bind:dialogStatus="dialog"
          >some generic stuff here</slot
        >
      </BaseCard>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'base-dialog' as string,
  props: {
    icon: {
      type: String,
      default: 'mdi-file-edit-outline',
    }, // mdi-{icon-name}
    color: { type: String, default: 'white' },
    title: { type: String, default: 'Info' },
    disabled: {
      type: Boolean,
      default: false,
    }, // The dialog open/activator button clickable or not
    tooltip: { type: String, default: 'Click me' },
    id: {
      type: String,
      default: '',
    }, // Add this to specifiy an instance of the base-dialog component
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    close: {
      handler(): void {
        this.closeDialog();
      },
      immediate: true,
    },
  },
  computed: {
    getIcon(): string {
      return this.icon;
    },
    getIconColor(): string {
      return this.color;
    },
    dialogTitle(): string {
      return this.title;
    },
    close(): number {
      return this.$store.getters['App/closeAllDialogs'];
    },
  },
  methods: {
    closeDialog(): void {
      // Note: This method will be called as many times as there instances of this component!
      this.dialog = false;
      this.$emit('dialog-closed');
    },
  },
});
</script>
