<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <template v-slot:activator="{ on }">
      <v-btn class="my-auto" :color="color" dark v-on="on" x-small v-if="btnSize === 'x-small'">{{
        btnText
      }}</v-btn>
      <v-btn class="my-auto" :color="color" dark v-on="on" small v-else-if="btnSize === 'small'">{{
        btnText
      }}</v-btn>
      <v-btn elevation="0" :color="color" class="my-auto pa-6" width="200px" v-on="on"
        v-else-if="btnSize === 'xx-large'">{{ btnText }}</v-btn>
      <v-btn class="my-auto" :color="color" dark v-on="on" v-else>{{ btnText }}</v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        <slot name="title">title</slot>
      </v-card-title>
      <v-card-text>
        <slot name="body">Some text here</slot>
        <v-checkbox v-if="isCohort == false" v-model="checkboxConfirm" :label="`Confirm you understand`"></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="isCohort == false" color="red" @click="accept" :disabled="!checkboxConfirm">{{ acceptText }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="reject">{{ rejectText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'base-confirm-actions' as string,
  props: {
    btnText: { type: String, default: 'Delete' },
    btnSize: { type: String, default: 'x-large' },
    acceptText: { type: String, default: 'Accept' },
    rejectText: { type: String, default: 'Reject' },
    color: { type: String, default: 'red' },
    isCohort: {}
  },
  data: () => ({
    dialog: false,
    checkboxConfirm: null,
  }),
  methods: {
    reject(): void {
      this.$emit('base-confirm-reject');
      this.checkboxConfirm = null;
      this.dialog = false;
    },
    accept(): void {
      this.$emit('base-confirm-accept');
      this.checkboxConfirm = null;
      this.dialog = false;
    },
  },
});
</script>
