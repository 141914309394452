import { Commit } from 'vuex';
import { Cohort, listAll, cohortSave, cohortUpdate } from '@/store/apis';
import Vue from 'vue';
import { cohortDelete } from '../../apis/cohort';

export const actions = {
  async fetchAll({ commit }: { commit: Commit }, organisationId: string) {
    if (!organisationId || organisationId.length <= 0) return;
    try {
      commit('Loading/START_LOADING', null, { root: true });
      commit('RESET');
      const data = await listAll(organisationId);
      commit('ADD_COLLECTION', data);
    } catch (error) {
      Vue.prototype.$toasted.error(`Cohorts fetch: ${error.message}`);
      Vue.prototype.$log({
        methodName: 'Cohort:fetch:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async createCohort({ commit }: { commit: Commit }, cohort: Cohort) {
    try {
      commit('Loading/START_LOADING', null, { root: true });
      const { data } = await cohortSave(cohort);
      commit('ADD_COHORT', data);
      commit('SELECTED', data.cohortId);
      Vue.prototype.$log({
        methodName: 'Cohort:create:success',
        newValue: data,
      });
    } catch (error) {
      Vue.prototype.$log({
        methodName: 'Cohort:create:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async updateCohort({ commit }: { commit: Commit }, cohort: Cohort) {
    try {
      commit('Loading/START_LOADING', null, { root: true });
      await cohortUpdate(cohort);
      commit('UPDATE_COHORT', cohort);
      Vue.prototype.$log({
        methodName: 'Cohort:update:success',
        newValue: cohort,
      });
    } catch (error) {
      Vue.prototype.$log({
        methodName: 'Cohort:update:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async deleteCohort({ commit }: { commit: Commit }, id: string) {
    try {
      commit('Loading/START_LOADING', null, { root: true });
      await cohortDelete(id);
      commit('REMOVE_COHORT', id);
      Vue.prototype.$log({
        methodName: 'Cohort:delete:success',
        deletedId: id,
      });
    } catch (error) {
      Vue.prototype.$log({
        methodName: 'Cohort:delete:error',
        newValue: error.message,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
};
