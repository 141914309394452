import Vue from 'vue';
import { loginRequest } from "../../auth/authConfig";

interface OptionsInterface {
  headers: {
    Authorization: string;
    'content-type': string;
  };
}
export const headers = async (service?: string): Promise<OptionsInterface> => {
  try {
    if (
      service &&
      service.indexOf('localhost') > -1 &&
      process.env.VUE_APP_APOLLO_GRAPHQL_CLIENT_TOKEN
    ) {
      return {
        headers: {
          Authorization: `${process.env.VUE_APP_APOLLO_GRAPHQL_CLIENT_TOKEN}`,
          'content-type': 'application/json',
        },
      };
    }
    const response = {
      headers: {
        Authorization: `Bearer ${await Vue.prototype.$auth.getTokenSilently(loginRequest)}`,
        'content-type': 'application/json',
      },
    };

    return response;
  } catch (err) {
    Vue.prototype.$toasted.info('Refresh the page');
    return {
      headers: {
        Authorization: '',
        'content-type': 'application/json',
      },
    };
  }
};

export const customHeaders = async (customHeaders = {}, url = '') => {
  try {
    const headerOptions: OptionsInterface = await headers(url);
    return { headers: { ...headerOptions.headers, ...customHeaders } };
  } catch (err) {
    Vue.prototype.$toasted.info(`Setting custom headers: need refresh`);
  }
};
