import Roles from './rolesAndAbilities';

export interface Role {
  id: string;
  name: string;
  description: string;
  abilities: string[];
}
export interface RolesState {
  roles: {
    list: Role[];
  };
}

const state: RolesState = {
  roles: {
    list: Roles(),
  },
};

const getters = {
  getRoles: (state: RolesState): Role[] => state.roles.list,
  abilities: (state: RolesState): string[] => {
    const abilities: string[] = [];

    state.roles.list.forEach((role) => {
      abilities.push(...role.abilities);
    });

    return [...new Set(abilities)];
  },
  getRoleById: (state: RolesState) => (id: string): Role | null =>
    state.roles.list.filter((role) => role.id === id)[0] || null,
  getAbilities: (state: RolesState) => (roles: string[]): string[] => {
    const abilities: string[] = [];
    state.roles.list.forEach((role) => {
      if (roles.some((item) => item === role.id)) {
        abilities.push(...role.abilities);
      }
    });
    return [...new Set(abilities)];
  },
  getSubsetsFromAbilities: (state: RolesState) => (abilities: string[]): string[] => {
    const response: string[] = [];

    state.roles.list.forEach((role) => {
      if (role.abilities.every((ability) => abilities.includes(ability))) {
        response.push(role.id);
      }
    });

    return response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
};
