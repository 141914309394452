export const UTC2ddmmyyyy = (str: string) => {
  try {
    return new Date(str).toISOString().split('T')[0];
  } catch (error) {
    return 'n/a';
  }
};

/*
 * This method returns and array of numbers representing days of the month.
 * E.g. start = 2020-10-02 end = 2020-10-06
 * Result is [2,3,4,5,6]
 */
export const getDatesBetweenDates = (startDate: string, endDate: string): string[] => {
  const dates: string[] = [];
  const start = new Date(startDate);
  const end = new Date(endDate);
  while (start <= end) {
    dates.push(getDateString(start));
    start.setDate(start.getDate() + 1);
  }
  return dates;
};

/*
 * Returns a string of yyyy-mm-dd from a Date object and accounts for timezone
 */
export const getDateString = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
};

/*
 * Returns an array of ["2020-01-01", "2020-02-01"]
 * Defaults to past 30 days
 */
export const defaultDateRange = (period = 30): string[] => {
  const today = new Date();
  const pastDate = new Date(today);
  pastDate.setDate(today.getDate() - period);
  return [pastDate.toISOString().substring(0, 10), today.toISOString().substring(0, 10)];
};

/*
 * If a date string is within the range of two dates return `true`
 */
export const dateWithinRange = (date: string, range: string[]): boolean => {
  const rootDate = new Date(date);
  const start = new Date(range[0]);
  const end = new Date(range[1]);
  if (start <= rootDate && rootDate <= end) return true;

  return false;
};
