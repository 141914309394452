var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-btn",
    { attrs: { icon: "", dark: "" } },
    [
      _c("v-icon", {
        attrs: { color: _vm.color, disabled: _vm.disabled },
        domProps: { textContent: _vm._s(_vm.$icon(this.icon)) },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.onclickMethod.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }