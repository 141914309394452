<template>
  <div class="d-flex flex-grow-1 flex-column">
    <a class="image mb-2" :style="imageStyle" @click="$refs.file.click()">
      <div class="content d-flex flex-column justify-center align-center">
        <BaseSvg icon="image" class="icon" v-if="!imageURL" />
        <v-btn class="mt-3" large color="primary" v-if="!imageURL && hideButton">
          {{ buttonText }}
        </v-btn>
      </div>
    </a>
    <v-btn class="mt-1" large color="primary" @click="$refs.file.click()" v-if="!hideButton">
      {{ buttonText }}
    </v-btn>
    <input ref="file" type="file" class="input" :accept="acceptType" @change="handleFileChange" />
  </div>
</template>
<style lang="scss" scoped>
.image {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background: #2d2d2d;
  background-size: contain;
  background-position: center;
  border-radius: 2px;
  background-repeat: no-repeat;
  .content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    i {
      font-size: 100px;
      color: #818181;
    }
  }
}
.icon {
  font-size: 100px;
  color: #818181;
  line-height: 1;
}

.input {
  display: none;
}
</style>
<script lang="ts">
import Vue from 'vue';
import { mbToBytes } from '@/lib/utils';
import { imageUpload, File } from '@/store/apis';

interface Data {
  newFileObject: string | null;
  file: File | null;
  url: string | null;
}

export default Vue.extend({
  name: 'base-uploader-compact',
  props: {
    buttonText: { type: String, default: 'Select Image' },
    name: { type: String, default: 'module' },
    imageURL: { type: String, default: '' },
    infoText: {
      type: String,
      default: 'Please note: Your selected icon should be a transparent .png file format.',
    },
    maxMB: { type: Number, default: 5000 },
    tags: {
      type: Array as () => string[],
      default: (): string[] => ['admin-file-uploader'],
    },
    type: { type: String, default: 'image' },
    hideButton: { type: Boolean, default: false },
    allowTypes: {
      type: Array as () => string[],
      default: (): string[] => ['png', 'jpg'],
    },
  },
  data: (): Data => ({
    newFileObject: null,
    file: null,
    url: null,
  }),
  watch: {
    imageURL: {
      handler(newValue) {
        this.url = newValue;
      },
      immediate: true,
    },
  },
  computed: {
    maxFileSize(): number {
      return this.$store.getters['App/maxImageUploadSize'];
    },
    getTags(): string {
      return this.tags.toString();
    },
    getmaxMB(): number {
      return this.maxFileSize > mbToBytes(this.maxMB) ? mbToBytes(this.maxMB) : this.maxFileSize;
    },
    imageStyle(): string {
      if (this.imageURL) {
        return `background-image:url('${this.imageURL}')`;
      }
      return '';
    },
    acceptType(): string {
      let returnString = [];
      if (this.allowTypes.indexOf('png') > -1) {
        returnString.push('image/x-png');
        returnString.push('image/png');
      }
      if (this.allowTypes.indexOf('jpg') > -1) {
        returnString.push('image/jpg');
        returnString.push('image/jpeg');
      }
      if (this.allowTypes.indexOf('gif') > -1) {
        returnString.push('image/gif');
      }
      if (this.allowTypes.indexOf('svg') > -1) {
        returnString.push('image/svg');
      }
      return returnString.join(',');
    },
  },
  methods: {
    validateSize(fileSize: number): void {
      if (fileSize > this.getmaxMB) {
        throw new Error(`File size "${fileSize}" exceeds ${this.getmaxMB} limit`);
      }
    },
    validateType(fileType: string): void {
      if (!fileType.startsWith(`${this.type}/`)) {
        throw new Error(`"${fileType}" is not valid`);
      }
    },
    async handleFileChange(event: any) {
      this.$store.commit('Loading/START_LOADING');

      try {
        const file = event.target.files[0];
        if (!file) return;

        this.validateSize(file.size);
        // this.validateType(this.file.type);

        // eslint-disable-next-line
        // @ts-ignore
        const { data } = await imageUpload(file, this.getTags);
        this.url = data.secure_url || data.url || 'temp';
        this.$toasted.success(`Uploaded: ${this.url} `);
        this.$emit('file-uploaded', this.url);
      } catch ({ message }) {
        this.$toasted.error(`Error creating: ${message} `);
      }
      // clear input value (without this when the user opens a new filesystem browser and hits 'cancel', it triggers this change)
      event.target.value = '';
      this.$store.commit('Loading/FINISH_LOADING');
    },
  },
});
</script>
