import { MutationTree } from 'vuex';
import * as I from '@/store/modules/programme/interfaces';
import MutationError from '@/lib/errors/MutationError';

export enum MutationTypes {
  SELECT_MODULE = 'SELECT_MODULE',
  DESELECT_MODULE = 'DESELECT_MODULE',
  MODULE_ADD = 'MODULE_ADD',
  MODULE_UPDATE = 'MODULE_UPDATE',
  MODULE_DELETE = 'MODULE_DELETE',
}

export type Mutations<S = I.ProgrammeState> = {
  [MutationTypes.SELECT_MODULE](state: S, payload: string): void;
  [MutationTypes.DESELECT_MODULE](state: S): void;
  [MutationTypes.MODULE_ADD](state: S, payload: I.Module): void;
  [MutationTypes.MODULE_UPDATE](state: S, payload: I.Module): void;
  [MutationTypes.MODULE_DELETE](state: S, payload: string): void;
};

export const mutations: MutationTree<I.ProgrammeState> & Mutations = {
  SELECT_MODULE(state: I.ProgrammeState, id: string) {
    state.programmes.selectedModuleId = id || null;
    state.programmes.selectedActivityId = null;
  },
  DESELECT_MODULE(state: I.ProgrammeState) {
    state.programmes.selectedModuleId = null;
    state.programmes.selectedActivityId = null;
  },
  MODULE_ADD(state: I.ProgrammeState, newModule: I.Module) {
    state.programmes.list.forEach((programme) => {
      if (programme._id === state.programmes.selectedProgrammeId) {
        programme.journeys.forEach((journey) => {
          if (journey._id === state.programmes.selectedJourneyId) {
            if (!journey.modules || journey.modules === undefined) {
              journey.modules = [newModule];
            } else {
              journey.modules.push(newModule);
            }
            state.programmes.selectedModuleId = newModule._id as string;
          }
        });
      }
    });
  },
  MODULE_UPDATE(state: I.ProgrammeState, editedModule: I.Module) {
    try {
      state.programmes.list.forEach((programme) => {
        if (programme._id === state.programmes.selectedProgrammeId) {
          programme.journeys.forEach((journey) => {
            if (journey._id === state.programmes.selectedJourneyId) {
              if (!journey.modules || journey.modules.length < 1) {
                journey.modules = [editedModule];
              } else {
                const index = journey.modules.findIndex((m) => m._id === editedModule._id);
                if (index !== -1) {
                  journey.modules.splice(index, 1, editedModule);
                }
              }
            }
          });
        }
      });
    } catch ({ message }) {
      throw new MutationError(message);
    }
  },
  MODULE_DELETE(state: I.ProgrammeState, deletedId: string) {
    state.programmes.list.forEach((programme) => {
      programme.journeys.forEach((journey) => {
        journey.modules = journey.modules?.filter(({ _id }) => {
          if (_id !== deletedId) {
            state.programmes.selectedModuleId = null;
            state.programmes.selectedActivityId = null;
            return true;
          }
        });
      });
    });
  },
};
