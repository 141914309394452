import { GetterTree } from 'vuex';
import * as I from '@/store/modules/programme/interfaces';

export type Getters = {
  activityById(state: I.ProgrammeState): I.Activity | null;
};

export const getters: GetterTree<I.ProgrammeState, I.ProgrammeState> & Getters = {
  activityById: (state: I.ProgrammeState) => {
    let activity = null;
    state.programmes.list.forEach((p) => {
      p.journeys.forEach((j) => {
        j.modules?.forEach((m) => {
          m.activities?.forEach((a) => {
            if (a._id === state.programmes.selectedActivityId) {
              activity = { ...a };
            }
          });
        });
      });
    });
    return activity;
  },
};
