import { MutationTree } from 'vuex';
import * as I from '@/store/modules/programme/interfaces';
import MutationError from '@/lib/errors/MutationError';

export enum MutationTypes {
  ADD_PROGRAMME_COLLECTION = 'ADD_PROGRAMME_COLLECTION',
  ADD_PROGRAMME = 'ADD_PROGRAMME',
  UPDATE_PROGRAMME = 'UPDATE_PROGRAMME',
  REMOVE_PROGRAMME = 'REMOVE_PROGRAMME',
  SELECTED_PROGRAMME = 'SELECTED_PROGRAMME',
  RESET_PROGRAMME = 'RESET_PROGRAMME',
}

export type Mutations<S = I.ProgrammeState> = {
  [MutationTypes.ADD_PROGRAMME_COLLECTION](state: S, payload: I.Programme[]): void;
  [MutationTypes.ADD_PROGRAMME](state: S, payload: I.Programme): void;
  [MutationTypes.UPDATE_PROGRAMME](state: S, payload: I.Programme): void;
  [MutationTypes.REMOVE_PROGRAMME](state: S, payload: string): void;
  [MutationTypes.SELECTED_PROGRAMME](state: S, payload: string | null): void;
  [MutationTypes.RESET_PROGRAMME](state: S): void;
};

export const mutations: MutationTree<I.ProgrammeState> & Mutations = {
  [MutationTypes.ADD_PROGRAMME_COLLECTION](state: I.ProgrammeState, programmes: I.Programme[]) {
    state.programmes.list = programmes;
    state.programmes.selectedProgrammeId = null;
    state.programmes.selectedJourneyId = null;
    state.programmes.selectedModuleId = null;
    state.programmes.selectedActivityId = null;
  },

  [MutationTypes.ADD_PROGRAMME](state: I.ProgrammeState, programme: I.Programme) {
    state.programmes.list.unshift(programme);
    state.programmes.selectedProgrammeId = programme._id || null;
    state.programmes.selectedJourneyId = null;
    state.programmes.selectedModuleId = null;
    state.programmes.selectedActivityId = null;
  },

  [MutationTypes.UPDATE_PROGRAMME](state: I.ProgrammeState, programme: I.Programme) {
    try {
      const index = state.programmes.list.findIndex(
        (prog: I.Programme) => prog._id === programme._id
      );
      if (index !== -1) {
        state.programmes.list.splice(index, 1, programme);
      }
    } catch ({ message }) {
      throw new MutationError(message);
    }
  },

  [MutationTypes.REMOVE_PROGRAMME](state: I.ProgrammeState, id: string) {
    state.programmes.list = state.programmes.list.filter((prog: I.Programme) => prog._id !== id);
    state.programmes.selectedProgrammeId = null;
    state.programmes.selectedJourneyId = null;
    state.programmes.selectedModuleId = null;
    state.programmes.selectedActivityId = null;
  },

  [MutationTypes.SELECTED_PROGRAMME](state: I.ProgrammeState, id: string | null) {
    state.programmes.selectedProgrammeId = id || null;
    state.programmes.selectedJourneyId = null;
    state.programmes.selectedModuleId = null;
    state.programmes.selectedActivityId = null;
  },

  [MutationTypes.RESET_PROGRAMME](state: I.ProgrammeState) {
    state.programmes.list = [];
    state.programmes.selectedProgrammeId = null;
    state.programmes.selectedJourneyId = null;
    state.programmes.selectedModuleId = null;
    state.programmes.selectedActivityId = null;
  },
};
