import { GetterTree } from 'vuex';
import * as I from '@/store/modules/reporting/interfaces';

export type Getters = {
  userLastSession(state: I.ReportingState): (userId: string) => string | null;
  userSessionHistory(state: I.ReportingState): (userId: string) => number | null;
};

export const getters: GetterTree<I.ReportingState, I.ReportingState> & Getters = {
  userLastSession: (state: I.ReportingState) => (userId: string) => {
    return (
      state.reporting.lastSessions.filter((s) => s.userId === userId)[0]?.lastSessionDate || null
    );
  },

  userSessionHistory: (state: I.ReportingState) => (userId: string) => {
    return state.reporting.sessionHistory.filter((s) => s.userId === userId)[0]?.dates?.length || 0;
  },
};
