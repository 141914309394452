import axios from 'axios';
import { headers } from '@/lib/utils';
import { Filter } from '@/store/modules/filter/interfaces';
import { Journey } from '@/store/apis';
import CircuitBreaker from 'opossum';

const BASE_URL = process.env.VUE_APP_REPORTING_API || '';

const CIRCUIT_BREAKER_OPTIONS = {
  timeout: 40000,
  errorThresholdPercentage: 50,
  resetTimeout: 10000,
};

export interface JourneyWithOutcomes {
  id: string;
  firstName: string;
  modules?: {
    _id: string;
    title: string;
    activities?: {
      _id: string;
      title: string;
      completedDate: string | null;
    }[];
  }[];
}

export interface UserWithJourney {
  id: string;
  firstName: string;
  lastName: string;
  journey: JourneyWithOutcomes;
}

export interface JourneyWithOutcomesExtended {
  users: UserWithJourney[];
  cohortId: string;
  journey: Journey;
}

export const lastSessions = async (dateFrom: string, dateTo: string) => {
  const url = `${BASE_URL}/query/last-sessions?dateFrom=${dateFrom}&dateTo=${dateTo}`;
  return axios.get(url, await headers()).then((response) => response);
};

export const sessionsCount = async (dateFrom: string, dateTo: string) => {
  const url = `${BASE_URL}/query/count-sessions?dateFrom=${dateFrom}&dateTo=${dateTo}`;
  return axios.get(url, await headers()).then((response) => response);
};

export const summaryStats = async (organisationId: string, filters: Filter) => {
  const url = `${BASE_URL}/goal/summary`;
  const body = {
    organisationId,
    filters,
  };
  return axios.post(url, body, await headers()).then((response) => response);
};

export const cohortProgress = async (organisationId: string) => {
  const url = `${BASE_URL}/report`;
  // const url = `${BASE_URL}/cohort/users/journey-progress`;
  const body = {
    organisationId,
  };

  const cb = new CircuitBreaker(
    async (url: string, body: unknown) => axios.post(url, body, await headers()),
    CIRCUIT_BREAKER_OPTIONS
  );

  return cb.fire(url, body);
};

export const cohortHabitSummary = async (organisationId: string) => {
  const url = `${BASE_URL}/goal/users/goals`;
  const body = {
    organisationId,
  };

  const cb = new CircuitBreaker(
    async (url: string, body: unknown) =>
      axios.post(url, body, await headers()).then((response) => response),
    CIRCUIT_BREAKER_OPTIONS
  );
  cb.fallback(() => 'This has failed: "cohortHabitSummary"');
  return cb.fire(url, body);
};
export const usersGoals = async (userIDs: string[]) => {
  const url = `${BASE_URL}/goal-v2/users/goals`;
  const body = {
    userIDs,
  };

  const cb = new CircuitBreaker(
    async (url: string, body: unknown) =>
      axios.post(url, body, await headers()).then((response) => response),
    CIRCUIT_BREAKER_OPTIONS
  );
  cb.fallback(() => 'This has failed: "usersGoals"');
  return cb.fire(url, body);
};

export const syncReports = async () => {
  const url = `${BASE_URL}/report/sync`;

  return axios.get(url, await headers()).then((response) => response);
};
