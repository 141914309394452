import Vue from 'vue';

import { fetchAuthUserProfile } from '@/store/apis';
import { Commit } from 'vuex';
interface AuthUser {
  displayName: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  orgId: string;
  roles: [string] | [];
}

interface AuthUserState {
  user: AuthUser;
}

const state: AuthUserState = {
  user: {
    roles: [],
  } as AuthUser,
};

const actions = {
  async fetchAuthenticatedUser({ commit }: { commit: Commit }) {
    try {
      const { data } = await fetchAuthUserProfile();
      if (data.roles.length <= 0) {
        throw new Error('No roles');
      }
      commit('setAuthenticatedUser', data);
    } catch (error) {
      Vue.prototype.$toasted.info(`If this persists then contact admin`);
    }
  },
};

const mutations = {
  setAuthenticatedUser: (state: AuthUserState, authUser: AuthUser) => {
    state.user = authUser;
  },
};

const getters = {
  getRoles: (state: AuthUserState) => state.user.roles,
  hasRole: (state: AuthUserState) => (roleString: string) => {
    if (
      (state.user.roles as string[]).includes(roleString) ||
      (state.user.roles as string[]).includes('system:administrator')
    ) {
      return true;
    } else {
      return false;
    }
  },
  user: (state: AuthUserState) => state.user,
  userLite: (state: AuthUserState) => {
    try {
      const user = {
        email: state.user.email,
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        id: state.user.id,
      };
      return user;
    } catch (error) {
      return {};
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
