const iconMap: { [key: string]: string } = {
  activity: 'run',
  // activitys: 'run',
  activities: 'run',
  add: 'plus-circle-outline',
  'add-fill': 'plus-circle',
  binoculars: 'binoculars',
  calendar: 'calendar-month-outline',
  'chevron-down': 'chevron-down',
  'chevron-up': 'chevron-up',
  close: 'close',
  cohort: 'home',
  cohorts: 'home-group',
  copy: 'content-copy',
  delete: 'trash-can-outline',
  download: 'file-download-outline',
  edit: 'file-edit-outline',
  filter: 'filter-outline',
  'generate-users': 'table-arrow-down',
  goal: 'ray-start-arrow',
  goals: 'ray-start-arrow',
  habit: 'reload',
  // habitBank: 'bank',
  habitbank: 'bank',
  help: 'help-rhombus-outline',
  hide: 'eye-off-outline',
  hidden: 'eye-off-outline',
  home: 'view-dashboard',
  image: 'file-image-outline',
  journey: 'map-outline',
  journeys: 'map-outline',
  library: 'file-document',
  link: 'link-variant',
  logout: 'exit-to-app',
  media: 'image-filter-vintage',
  minus: 'minus',
  module: 'view-module',
  modules: 'view-module',
  more: 'circle-expand',
  move: 'arrow-up-down',
  'move-up': 'arrow-up-bold',
  'move-down': 'arrow-down-bold',
  new: 'newspaper-plus',
  notselected: 'checkbox-blank-circle-outline',
  organisation: 'domain',
  organisations: 'domain',
  programme: 'rhombus-split',
  programmes: 'rhombus-split',
  reminder: 'calendar-clock-outline',
  report: 'file-chart',
  reports: 'file-chart',
  reporting: 'file-chart',
  return: 'keyboard-return',
  role: 'ticket',
  roles: 'ticket',
  sad: 'emoticon-sad-outline',
  search: 'magnify',
  selected: 'check-circle',
  settings: 'cog',
  show: 'eye-outline',
  status: 'cellphone',
  test: 'test-tube',
  text: 'format-text',
  textbox: 'text-box-outline',
  'textbox-multiple': 'text-box-multiple-outline',
  tick: 'check',
  toolkit: 'image-filter-vintage',
  tooltip: 'help-rhombus-outline',
  trash: 'trash-can-outline',
  unselected: 'check-circle-outline',
  user: 'account',
  users: 'account-group',
  'user-checked': 'account-check',
  view: 'magnify',
  visible: 'eye-outline',
  video: 'video-vintage',
  warn: 'alert-circle',
  warning: 'alert-circle',
};

export default (StR: string): string =>
  // eslint-disable-next-line no-prototype-builtins
  iconMap.hasOwnProperty(StR.toLowerCase())
    ? `mdi-${iconMap[StR.toLowerCase()].toString()}`
    : 'mdi-cursor-default-click';
