var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-grow-1 flex-column" },
    [
      _c(
        "a",
        {
          staticClass: "image mb-2",
          style: _vm.imageStyle,
          on: {
            click: function ($event) {
              return _vm.$refs.file.click()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "content d-flex flex-column justify-center align-center",
            },
            [
              !_vm.imageURL
                ? _c("BaseSvg", {
                    staticClass: "icon",
                    attrs: { icon: "image" },
                  })
                : _vm._e(),
              !_vm.imageURL && _vm.hideButton
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mt-3",
                      attrs: { large: "", color: "primary" },
                    },
                    [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      !_vm.hideButton
        ? _c(
            "v-btn",
            {
              staticClass: "mt-1",
              attrs: { large: "", color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$refs.file.click()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
          )
        : _vm._e(),
      _c("input", {
        ref: "file",
        staticClass: "input",
        attrs: { type: "file", accept: _vm.acceptType },
        on: { change: _vm.handleFileChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }