import Vue from 'vue';
import { ActionTree, ActionContext, DispatchOptions } from 'vuex';
import * as I from '@/store/modules/programme/interfaces';
import { MutationTypes, Mutations } from './mutations';
import { moduleCreate, moduleUpdate, moduleDelete, DBModule } from '@/store/apis';

import { ActionTypes as JourneyActionTypes, Actions as JourneyActions } from '../journey/actions';
import { Getters as JourneyGetters } from '@/store/modules/programme/journey/getters';

export enum ActionTypes {
  MODULE_CREATE = 'moduleCreate',
  MODULE_EDIT = 'moduleEdit',
  MODULE_DELETE = 'moduleDelete',
  MODULE_INSERT = 'moduleInsert',
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K | string,
    payload: Parameters<Mutations[K]>[1] | null,
    options?: { root: boolean }
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<I.ProgrammeState, I.ProgrammeState>, 'commit'>;

type AugmentedActionDispatch = {
  commit<K extends keyof Mutations>(
    key: K | string,
    payload: Parameters<Mutations[K]>[1] | null,
    options?: { root: boolean }
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof JourneyActions>(
    key: K,
    payload: Parameters<JourneyActions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<JourneyActions[K]>;
} & {
  getters: {
    [K in keyof JourneyGetters]: ReturnType<JourneyGetters[K]>;
  };
} & Omit<ActionContext<I.ProgrammeState, I.ProgrammeState>, 'dispatch'>;

export interface Actions {
  [ActionTypes.MODULE_CREATE](
    { commit, dispatch }: AugmentedActionDispatch,
    payload: I.Journey
  ): Promise<void>;
  [ActionTypes.MODULE_EDIT]({ commit }: AugmentedActionContext, payload: I.Journey): Promise<void>;
  [ActionTypes.MODULE_DELETE]({ commit }: AugmentedActionContext, payload: string): Promise<void>;
  [ActionTypes.MODULE_INSERT](
    { commit, dispatch }: AugmentedActionDispatch,
    payload: I.Module
  ): Promise<void>;
}

export const actions: ActionTree<I.ProgrammeState, I.ProgrammeState> & Actions = {
  async moduleCreate({ commit, dispatch, getters }, nModule: I.Module) {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      const { data } = await moduleCreate(nModule);
      commit(MutationTypes.MODULE_ADD, data);
      const journey = getters.journeyById;
      await dispatch(JourneyActionTypes.JOURNEY_EDIT, {
        id: journey._id,
        body: {
          ...journey,
          modules: (journey.modules as I.Module[]).map((m) => m._id),
        },
      });
      Vue.prototype.$log({
        methodName: 'Module:create',
        newValue: data,
      });
    } catch (error) {
      Vue.prototype.$log({
        methodName: 'Module:create:error',
        newValue: error,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async moduleEdit({ commit }, body: I.Module) {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      if (body._id) {
        const activities = body.activities
          ?.filter(({ _id }) => {
            if (_id) {
              return true;
            }
          })
          .map(({ _id }) => _id);

        const module = {
          ...body,
          activities,
        } as DBModule;
        const { data } = await moduleUpdate(body._id, module);
        Vue.prototype.$log({
          methodName: 'Module:edit',
          newValue: data,
        });
        commit(MutationTypes.MODULE_UPDATE, body);
      } else {
        throw new Error('No valid ID passed');
      }
    } catch (error) {
      Vue.prototype.$log({
        methodName: 'Module:edit:error',
        newValue: error,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async moduleDelete({ commit }, id: string) {
    commit('Loading/START_LOADING', null, { root: true });
    try {
      await moduleDelete(id);
      Vue.prototype.$log({
        methodName: 'Module:delete',
        deletedId: id,
      });
      commit(MutationTypes.MODULE_DELETE, id);
      commit(MutationTypes.DESELECT_MODULE, null);
    } catch (error) {
      Vue.prototype.$log({
        methodName: 'Module:delete:error',
        newValue: error,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
  async moduleInsert({ commit, dispatch, getters }, module: I.Module) {
    try {
      commit('Loading/START_LOADING', null, { root: true });
      const journey = getters.journeyById;
      const modules = (journey.modules as I.Module[]).map((m) => m._id);
      modules.push(module._id);
      await dispatch(JourneyActionTypes.JOURNEY_EDIT, {
        id: journey._id,
        body: {
          ...journey,
          modules,
        },
      });
      Vue.prototype.$log({
        methodName: 'Module:Insert',
        newValue: module,
      });
    } catch (error) {
      Vue.prototype.$log({
        methodName: 'Module:insert:error',
        newValue: error,
      });
      throw error;
    } finally {
      commit('Loading/FINISH_LOADING', null, { root: true });
    }
  },
};
