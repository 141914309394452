<template>
  <div
    v-if="shouldRender"
    :class="classes"
    v-html="require('!!html-loader!../../assets/icon/' + this.icon + '.svg')"
    
  ></div>
  <div
  v-else
    :class="classes"
    v-html="require('!!html-loader!../../assets/icon/' + this.icon + '.svg')"
    v-once
  ></div>
</template>
<style lang="scss">
svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.small,
.medium,
.large,
.xlarge {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.small svg {
  display: block;
  margin: auto auto;
  width: 1.2em;
  height: 1.2em;
}

.medium svg {
  display: block;
  margin: auto auto;
  width: 1.5em;
  height: 1.5em;
}

.large svg {
  display: block;
  margin: auto auto;
  width: 2em;
  height: 2em;
}

.xlarge svg {
  display: block;
  margin: auto auto;
  width: 3em;
  height: 3em;
}
.fill-current {
  fill: currentColor;
}
</style>
<script>
export default {
  props: {
    icon: { type: String },
    classes: { type: String },
    shouldRender: { type: Boolean, default:false },
  }
};
</script>
