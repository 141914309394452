var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "290" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _vm.btnSize === "x-small"
                ? _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "my-auto",
                        attrs: { color: _vm.color, dark: "", "x-small": "" },
                      },
                      on
                    ),
                    [_vm._v(_vm._s(_vm.btnText))]
                  )
                : _vm.btnSize === "small"
                ? _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "my-auto",
                        attrs: { color: _vm.color, dark: "", small: "" },
                      },
                      on
                    ),
                    [_vm._v(_vm._s(_vm.btnText))]
                  )
                : _vm.btnSize === "xx-large"
                ? _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "my-auto pa-6",
                        attrs: {
                          elevation: "0",
                          color: _vm.color,
                          width: "200px",
                        },
                      },
                      on
                    ),
                    [_vm._v(_vm._s(_vm.btnText))]
                  )
                : _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "my-auto",
                        attrs: { color: _vm.color, dark: "" },
                      },
                      on
                    ),
                    [_vm._v(_vm._s(_vm.btnText))]
                  ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _vm._t("title", function () {
                return [_vm._v("title")]
              }),
            ],
            2
          ),
          _c(
            "v-card-text",
            [
              _vm._t("body", function () {
                return [_vm._v("Some text here")]
              }),
              _vm.isCohort == false
                ? _c("v-checkbox", {
                    attrs: { label: `Confirm you understand` },
                    model: {
                      value: _vm.checkboxConfirm,
                      callback: function ($$v) {
                        _vm.checkboxConfirm = $$v
                      },
                      expression: "checkboxConfirm",
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
          _c(
            "v-card-actions",
            [
              _vm.isCohort == false
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "red", disabled: !_vm.checkboxConfirm },
                      on: { click: _vm.accept },
                    },
                    [_vm._v(_vm._s(_vm.acceptText))]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "white", text: "" },
                  on: { click: _vm.reject },
                },
                [_vm._v(_vm._s(_vm.rejectText))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }