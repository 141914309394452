import { GetterTree } from 'vuex';
import * as I from '@/store/modules/programme/interfaces';

export type Getters = {
  journeys(state: I.ProgrammeState): I.Journey[] | [];
  journeyById(state: I.ProgrammeState): I.Journey | null;
  modules(state: I.ProgrammeState): I.Module[] | [];
  allJourneys(state: I.ProgrammeState): I.Journey[] | [];
  anyJourneyById(state: I.ProgrammeState): (id: string) => I.Journey | null;
  selectedJourney(state: I.ProgrammeState): I.Journey | null;
};

export const getters: GetterTree<I.ProgrammeState, I.ProgrammeState> & Getters = {
  journeys: (state: I.ProgrammeState) => {
    const journeys =
      state.programmes.list.filter(
        (prog) => prog._id === state.programmes.selectedProgrammeId
      )[0] || [];
    if (!journeys.journeys) {
      return [];
    } else {
      return journeys.journeys.sort((a, b) => {
        if (a.title < b.title) return -1;

        if (a.title > b.title) return 1;

        return 0;
      });
    }
  },
  journeyById: (state: I.ProgrammeState) => {
    let journey = null;
    const index = state.programmes.list.findIndex(
      (p: I.Programme) => p._id === state.programmes.selectedProgrammeId
    );
    if (index !== -1) {
      state.programmes.list[index].journeys.forEach((j) => {
        if (j._id === state.programmes.selectedJourneyId) {
          journey = j;
        }
      });
    }
    return journey;
  },
  modules: (state: I.ProgrammeState) => {
    let journey = {} as I.Journey;
    const index = state.programmes.list.findIndex(
      (p: I.Programme) => p._id === state.programmes.selectedProgrammeId
    );
    if (index !== -1) {
      state.programmes.list[index].journeys.forEach((j) => {
        if (j._id === state.programmes.selectedJourneyId) {
          journey = j;
        }
      });
    }
    return journey?.modules || [];
  },
  allJourneys: (state: I.ProgrammeState) => {
    const journeys = [] as I.Journey[];
    state.programmes.list.forEach((p) => {
      journeys.push(...p.journeys);
    });

    return journeys;
  },
  anyJourneyById: (state: I.ProgrammeState) => (id: string) => {
    let journey = null;
    state.programmes.list.forEach((p) => {
      p.journeys.forEach((j) => {
        if (j._id === id) {
          journey = j;
        }
      });
    });
    return journey;
  },
  selectedJourney: (state: I.ProgrammeState) => {
    if (!state.programmes.selectedJourneyId) return null;

    const index = state.programmes.list.findIndex(
      (p) => p._id === state.programmes.selectedProgrammeId
    );
    if (index === -1) {
      return null;
    } else {
      return (
        state.programmes.list[index].journeys.filter(
          (j) => j._id === state.programmes.selectedJourneyId
        )[0] || null
      );
    }
  },
};
