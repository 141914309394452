import Loading from '@/components/base/base-loading.vue';
import { VueConstructor } from 'vue';

// Columns
import BaseColumnContainer from '@/components/base/columns/base-column-container.vue';
import BaseColumn from '@/components/base/columns/base-column.vue';

// v-card
import BaseCard from '@/components/base/base-v-card.vue';
import BaseStatsCard from '@/components/base/base-stats-card.vue';

// Dialogs & Notifications
import BaseDialog from '@/components/base/base-dialog.vue';
import BaseSimpleDialog from '@/components/base/base-dialog-no-activator.vue';
import BaseDialogActions from '@/components/base/base-dialog-actions.vue';
import BaseDialogActionsMessage from '@/components/base/base-dialog-actions-message.vue';
import BaseConfirm from '@/components/base/base-confirm.vue';
import BaseConfirmActions from '@/components/base/base-confirm-actions.vue';
import BaseSnackbar from '@/components/base/base-snackbar.vue';

import BaseBtn from '@/components/base/base-icon-button.vue';
import BaseSelect from '@/components/base/base-select.vue';
import BaseSearchInfo from '@/components/base/base-search-info-card.vue';

// Strings
import BaseTitle from '@/components/base/base-title.vue';

// Alert
import BaseInfoAlert from '@/components/base/base-info-alert.vue';

// Uploader
import BaseUploader from '@/components/base/uploader/base-uploader.vue';
import BaseUploaderCompact from '@/components/base/uploader/base-uploader-compact.vue';

import BasePopUpMenu from '@/components/base/base-popup-menu.vue';

// CMS block
import BaseCMSBlock from '@/components/base/base-CMS-block.vue';

import BaseSystemBar from '@/components/base/base-system-bar-controls.vue';

// Charts
import BaseChart from '@/components/base/base-chart-card.vue';

//svg
import BaseSvg from '@/components/base/base-svg.vue';

// Buttons
import BaseBackBtn from '@/components/base/base-back-btn.vue';

export default (Vue: VueConstructor) => {
  Vue.component('BaseLoading', Loading);
  Vue.component('BaseColumnContainer', BaseColumnContainer);
  Vue.component('BaseColumn', BaseColumn);
  Vue.component('BaseDialog', BaseDialog);
  Vue.component('BaseSimpleDialog', BaseSimpleDialog);
  Vue.component('BaseDialogActions', BaseDialogActions);
  Vue.component('BaseDialogActionsMessage', BaseDialogActionsMessage);
  Vue.component('BaseConfirm', BaseConfirm);
  Vue.component('BaseConfirmActions', BaseConfirmActions);
  Vue.component('BaseSnackbar', BaseSnackbar);
  Vue.component('BaseBtn', BaseBtn);
  Vue.component('BaseSelect', BaseSelect);
  Vue.component('BaseTitle', BaseTitle);
  Vue.component('BaseInfoAlert', BaseInfoAlert);
  Vue.component('BaseUploader', BaseUploader);
  Vue.component('BaseUploaderCompact', BaseUploaderCompact);
  Vue.component('BaseSearchInfo', BaseSearchInfo);
  Vue.component('BaseCard', BaseCard);
  Vue.component('BasePopUpMenu', BasePopUpMenu);
  Vue.component('BaseCMSBlock', BaseCMSBlock);
  Vue.component('BaseSystemBar', BaseSystemBar);
  Vue.component('BaseStatsCard', BaseStatsCard);
  Vue.component('BaseChart', BaseChart);
  Vue.component('BaseSvg', BaseSvg);
  Vue.component('BaseBackBtn', BaseBackBtn);
};
