var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "pa-1 ma-1 flex-grow-1",
      attrs: { "min-width": _vm.minWidth, outlined: "", tile: "" },
    },
    [
      _c("v-card-title", { staticClass: "justify-center text-h4" }, [
        _vm._v(_vm._s(_vm.stat.value || "n/a")),
      ]),
      _c("v-card-text", { staticClass: "text-center" }, [
        _vm._v(_vm._s(_vm.stat.title)),
      ]),
      _c(
        "v-fade-transition",
        [
          _c(
            "v-overlay",
            {
              attrs: {
                value: _vm.overlay,
                absolute: "",
                color: "#036358",
                opacity: _vm.opacity,
              },
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }