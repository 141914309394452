var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    {
      staticClass: "narrow",
      attrs: { dense: "", dark: "", tile: "", flat: "" },
    },
    [
      _vm.allowDelete
        ? _c("BaseConfirm", {
            attrs: {
              btnText: "Delete",
              btnSize: "x-small",
              acceptText: "Yes delete",
              rejectText: "no cancel",
            },
            on: {
              "base-confirm-accept": function ($event) {
                return _vm.$emit("system-bar-delete-event")
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Delete?")]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _vm._v("Are you sure, as it is not possible to undo?"),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              183355870
            ),
          })
        : _vm._e(),
      _c("v-spacer"),
      _c(
        "v-btn",
        {
          staticClass: "narrow-btn",
          attrs: { "x-small": "", color: "green" },
          on: {
            click: function ($event) {
              return _vm.$emit("system-bar-save-event")
            },
          },
        },
        [_vm._v("Save")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }